import React, { useState } from "react";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import { adminAddRestaurantAmount } from "../utilies/api";

const AddRestaurantAmount = ({
  show,
  handleClose,
  allRestaurants,
  idx,
  setIsLoading,
}) => {
  const [userLoading, setUserLodaing] = useState(false);
  const [userSuccMsg, setUserSuccMsg] = useState("");
  const [userErrMsg, setUserErrMsg] = useState("");
  const [restAmount, setRestAmount] = useState(
    allRestaurants[idx].RestaurantSubscriptions[0]?.amount
  );
  const addRestaurantAmount = async () => {
    try {
      const formData = {
        amount: restAmount,
      };
      setUserLodaing(true);
      const response = await adminAddRestaurantAmount(
        formData,
        allRestaurants[idx].id
      );
      setUserLodaing(false);
      setUserSuccMsg(response.data.message);
      setTimeout(() => {
        handleClose();
        setUserSuccMsg("");
        setUserErrMsg("");
        setIsLoading(true);
      }, 2000);
    } catch (error) {
      setUserLodaing(false);
      setUserErrMsg(error.response.data.message);
      setTimeout(() => {
        setUserErrMsg("");
        setUserSuccMsg("");
      }, 2000);
    }
  };
  return (
    <Modal aria-labelledby="contained-modal-title-vcenter" show={show}>
      <Modal.Header className="align-items-center justify-content-center border-0 flex-column">
        <h1
          className="mb-3"
          style={{
            fontSize: "32px",
            fontWeight: "bold",
            textDecoration: "capitalize",
          }}
        >
          Add Restaurant Amount
        </h1>
        <h5 style={{ fontSize: "18px", textDecoration: "capitalize" }}>
          Add Amount to{" "}
          <b style={{ color: "#425FAB", textDecoration: "capitalize" }}>
            {allRestaurants[idx].name}
          </b>
        </h5>
      </Modal.Header>
      {userSuccMsg ? (
        <div className="alert alert-success m-3 mb-0" role="alert">
          {userSuccMsg}
        </div>
      ) : null}
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3 fw-bold" controlId="add-amount">
            <Form.Label>Subscription Amount</Form.Label>
            <Form.Control
              type="number"
              min={1}
              placeholder="Subscription Amount"
              value={restAmount}
              onChange={(e) => setRestAmount(e.target.value)}
            />
            {userErrMsg ? (
              <span
                className="d-block text-danger text-capitalize my-2 mb-0"
                role="alert"
              >
                {userErrMsg}
              </span>
            ) : null}
          </Form.Group>
        </Form>
      </Modal.Body>
      <div className="d-flex justify-content-center align-items-center gap-3 mb-4">
        <Button
          variant="secondary"
          className="w-25 py-2 text-white fs-5 fw-bold rounded-5"
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          style={{ backgroundColor: "#415FAC" }}
          className="w-25 py-2 text-white fs-5 fw-bold rounded-5"
          onClick={() => {
            addRestaurantAmount();
            setUserLodaing(true);
          }}
        >
          {userLoading ? <Spinner /> : "Save"}
        </Button>
      </div>
    </Modal>
  );
};

export default AddRestaurantAmount;
