import React, { useState } from "react";
import "./editSubscriber.css";
import RestDashNav from "../../Components/RestDashNav/RestDashNav";
import Select from "react-select";
import {
  Col,
  Container,
  Form,
  FormGroup,
  InputGroup,
  Row,
} from "react-bootstrap";

const EditSubscriber = () => {
  const [inputChecked, setInputChecked] = useState(true);
  const options = [
    { value: "ahmed-mohamed", label: "Ahmed Mohamed" },
    { value: "sara-ali", label: "Sara Ali" },
    { value: "adel-ashraf", label: "Adel Ashraf" },
    { value: "youssef-ashraf", label: "youssef Ashraf" },
    { value: "tony-malak", label: "tony malak" },
    // Add more options here
  ];
  const [selectedOptions, setSelectedOptions] = useState(options.slice(2));
  const handleChange = (selected) => {
    setSelectedOptions(selected);
  };
  return (
    <>
      <RestDashNav
        title={"Edit Subscriber"}
        redirect_path={"/real-time-agents"}
      />
      <Container className="mt-3">
        <div className="container-section new-subscriber">
          <h3>Subscriber Details</h3>
          <label class="custom-checkbox">
            <input
              onChange={() => setInputChecked(!inputChecked)}
              checked={inputChecked}
              type="checkbox"
            />
            <span class="checkmark"></span>
            New Subscriber
          </label>
          <FormGroup className="form">
            <Row>
              <Col lg={6} className="mb-4">
                <label htmlFor="name">Subscriber Name</label>
                <Form.Select
                  name={"sname"}
                  //   onChange={(e) => handleSelect(filter.name, e.target.value)}
                  defaultValue={"Select or add new subscribre "}
                >
                  <option disabled value={""}>
                    Select or add new subscribre
                  </option>
                  <option selected value={"upper"}>
                    Upper Crust
                  </option>
                </Form.Select>
              </Col>
              <Col lg={6} className="mb-4">
                <label htmlFor="name">Email Address</label>
                <InputGroup>
                  <Form.Control
                    type="email"
                    name="email"
                    placeholder="Enter Email Address"
                    value={"uppercrust@smartserve.com"}
                    // onChange={handleChange}
                  />
                </InputGroup>
              </Col>
              <Col lg={6} className="mb-4">
                <label htmlFor="name">Phone Number</label>
                <InputGroup>
                  <Form.Control
                    type="tel"
                    name="phone"
                    placeholder="Phone Number"
                    value={"+962 7 9707 3022"}
                    // onChange={handleChange}
                  />
                </InputGroup>
              </Col>
              <Col lg={6} className="mb-4">
                <label htmlFor="name">Subscription Date</label>
                <InputGroup>
                  <Form.Control
                    type="date"
                    name="date"
                    placeholder="Subscription Date"
                    value={"2024-07-01"}
                    // onChange={handleChange}
                  />
                </InputGroup>
              </Col>
            </Row>
            {/* account creation */}
            <hr />
            <Row className="mt-4">
              <h3 className="mb-5">Account Creation</h3>
              <Col lg={6} className="mb-4">
                <label htmlFor="name">Password</label>
                <InputGroup>
                  <Form.Control
                    type="Password"
                    name="password"
                    placeholder="Password"
                    value={"123456789"}
                    // onChange={handleChange}
                  />
                </InputGroup>
              </Col>
              <Col lg={6} className="mb-4">
                <label htmlFor="name">Confirm Password</label>
                <InputGroup>
                  <Form.Control
                    type="Password"
                    name="cpassword"
                    placeholder="Password"
                    value={"123456789"}
                    // onChange={handleChange}
                  />
                </InputGroup>
              </Col>
            </Row>
            <hr />
            <Row className="mt-4 mb-4">
              <Col lg={6}>
                <h3 className="mb-0">Manage Agents</h3>
              </Col>
              <Col lg={6}>
                <div className="d-flex align-items-center justify-content-end">
                  <button className="add-new-user">Add New User</button>
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={12} className="mb-4">
                <label htmlFor="agent-select">Assign Agents</label>
                <Select
                  id="agent-select"
                  isMulti
                  value={selectedOptions}
                  onChange={handleChange}
                  options={options}
                  placeholder="Select one or mulyiple agents form your users"
                  classNamePrefix="react-select"
                />
              </Col>
            </Row>
            <div className="d-flex align-items-center justify-content-end">
              <button className="save">Save</button>
            </div>
          </FormGroup>
        </div>
      </Container>
    </>
  );
};

export default EditSubscriber;
