import { Link } from "react-router-dom";
import { Container, Spinner } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import mainLogo from "../../assets/RestDash/mainLogo.svg";
import DashBoardFooter from "../../Components/DashBoardFooter/DashBoardFooter";
import { useState } from "react";
import { userResetPassword } from "../../utilies/api";
import { notifyError, notifySuccess } from "../../Components/toasters";

const ForgetPassword = () => {
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const handleResetPassword = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      const response = await userResetPassword({
        email,
      });
      notifySuccess(response.data.message);
      setEmail("");
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      notifyError(error.response.data.message);
    }
  };
  return (
    <div className="login forget">
      <Container>
        <img className="login-logo mb-5" src={mainLogo} alt="logo" />
        <ToastContainer />
        <div className="side-form">
          <div className="side-form-section">
            <h1 className="mb-3">Forget Password</h1>
            <p className="mb-5">
              Enter your registered email address below, and we'll send you
              <br /> a link to reset your password.
            </p>
            <form className="form" onSubmit={handleResetPassword}>
              <label className="mb-2" htmlFor="email">
                Email Address
              </label>
              <input
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                autoComplete="true"
                className="form-input"
                type="email"
                name="email"
                id="email"
                placeholder="Enter Your Email Address"
              />
              <button className="login-button my-4" type="submit">
                {isLoading ? <Spinner /> : "Send"}
              </button>
              <div className="forget-link mb-2 text-center">
                <Link to="/login" className="forget">
                  Back to login
                </Link>
              </div>
            </form>
          </div>
        </div>
        <div className="text-center mt-3">
          <DashBoardFooter title={"© 2024 SmartServe"} />
        </div>
      </Container>
    </div>
  );
};

export default ForgetPassword;
