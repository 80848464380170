// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.my-orders {
  padding: 20px;
}
.my-orders h1 {
  font-size: 20px;
  font-weight: bold;
  font-family: sans-serif;
  letter-spacing: 1px;
  margin-bottom: 15px;
}
/* inputs */
.my-orders .form-control,
.my-orders .form-select {
  border-radius: 10px;
  height: 40px;
  font-size: 15px;
  font-weight: bold;
  font-family: sans-serif;
  letter-spacing: 1px;
  border: 2px solid rgba(226, 226, 226, 1);
  color: grey;
  margin-bottom: 15px;
}

.my-orders .table-container {
  border: 2px solid rgba(226, 226, 226, 0.93);
  border-radius: 10px;
  padding: 15px 20px;
  margin-bottom: 25px;
}
/* table */
.my-orders table tbody td span {
  color: rgba(0, 117, 80, 1);
  font-weight: bold;
}
.my-orders table p {
  font-weight: 500;
}
.rest-status span {
  background-color: #129d54;
  color: white !important;
  border-radius: 100px;
  padding: 5px 15px;
}
.rest-status img {
  cursor: pointer;
}

/* Chrome, Safari, Edge, Opera */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
`, "",{"version":3,"sources":["webpack://./src/Components/RestaurantLists/restaurantLists.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;AACA;EACE,eAAe;EACf,iBAAiB;EACjB,uBAAuB;EACvB,mBAAmB;EACnB,mBAAmB;AACrB;AACA,WAAW;AACX;;EAEE,mBAAmB;EACnB,YAAY;EACZ,eAAe;EACf,iBAAiB;EACjB,uBAAuB;EACvB,mBAAmB;EACnB,wCAAwC;EACxC,WAAW;EACX,mBAAmB;AACrB;;AAEA;EACE,2CAA2C;EAC3C,mBAAmB;EACnB,kBAAkB;EAClB,mBAAmB;AACrB;AACA,UAAU;AACV;EACE,0BAA0B;EAC1B,iBAAiB;AACnB;AACA;EACE,gBAAgB;AAClB;AACA;EACE,yBAAyB;EACzB,uBAAuB;EACvB,oBAAoB;EACpB,iBAAiB;AACnB;AACA;EACE,eAAe;AACjB;;AAEA,gCAAgC;AAChC;;EAEE,wBAAwB;EACxB,SAAS;AACX;;AAEA,YAAY;AACZ;EACE,0BAA0B;AAC5B","sourcesContent":[".my-orders {\n  padding: 20px;\n}\n.my-orders h1 {\n  font-size: 20px;\n  font-weight: bold;\n  font-family: sans-serif;\n  letter-spacing: 1px;\n  margin-bottom: 15px;\n}\n/* inputs */\n.my-orders .form-control,\n.my-orders .form-select {\n  border-radius: 10px;\n  height: 40px;\n  font-size: 15px;\n  font-weight: bold;\n  font-family: sans-serif;\n  letter-spacing: 1px;\n  border: 2px solid rgba(226, 226, 226, 1);\n  color: grey;\n  margin-bottom: 15px;\n}\n\n.my-orders .table-container {\n  border: 2px solid rgba(226, 226, 226, 0.93);\n  border-radius: 10px;\n  padding: 15px 20px;\n  margin-bottom: 25px;\n}\n/* table */\n.my-orders table tbody td span {\n  color: rgba(0, 117, 80, 1);\n  font-weight: bold;\n}\n.my-orders table p {\n  font-weight: 500;\n}\n.rest-status span {\n  background-color: #129d54;\n  color: white !important;\n  border-radius: 100px;\n  padding: 5px 15px;\n}\n.rest-status img {\n  cursor: pointer;\n}\n\n/* Chrome, Safari, Edge, Opera */\ninput[type=\"number\"]::-webkit-outer-spin-button,\ninput[type=\"number\"]::-webkit-inner-spin-button {\n  -webkit-appearance: none;\n  margin: 0;\n}\n\n/* Firefox */\ninput[type=\"number\"] {\n  -moz-appearance: textfield;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
