// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sideMenu {
  width: 100%;
  height: 100%;
  text-align: center;
  padding-top: 20px;
  border-right: 3px solid rgba(226, 226, 226, 1);
  position: relative;
}

.sideMenu ul a {
  padding: 10px 0;
  margin-bottom: 25px;
  cursor: pointer;
  position: relative;
  display: block;
}
.sideMenu ul .active::before {
  content: "";
  position: absolute;
  width: 6px;
  left: 0;
  bottom: 0;
  border-radius: 10px;
  height: 100%;
  background-color: #415fac;
}

.sideMenu .logOut-icon {
  position: absolute;
  bottom: 15px;
  width: 100%;
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/Components/RestDashSideMenu/restDashSideMenu.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,iBAAiB;EACjB,8CAA8C;EAC9C,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,mBAAmB;EACnB,eAAe;EACf,kBAAkB;EAClB,cAAc;AAChB;AACA;EACE,WAAW;EACX,kBAAkB;EAClB,UAAU;EACV,OAAO;EACP,SAAS;EACT,mBAAmB;EACnB,YAAY;EACZ,yBAAyB;AAC3B;;AAEA;EACE,kBAAkB;EAClB,YAAY;EACZ,WAAW;EACX,eAAe;AACjB","sourcesContent":[".sideMenu {\n  width: 100%;\n  height: 100%;\n  text-align: center;\n  padding-top: 20px;\n  border-right: 3px solid rgba(226, 226, 226, 1);\n  position: relative;\n}\n\n.sideMenu ul a {\n  padding: 10px 0;\n  margin-bottom: 25px;\n  cursor: pointer;\n  position: relative;\n  display: block;\n}\n.sideMenu ul .active::before {\n  content: \"\";\n  position: absolute;\n  width: 6px;\n  left: 0;\n  bottom: 0;\n  border-radius: 10px;\n  height: 100%;\n  background-color: #415fac;\n}\n\n.sideMenu .logOut-icon {\n  position: absolute;\n  bottom: 15px;\n  width: 100%;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
