import { Container, Spinner } from "react-bootstrap";
import "./login.css";
import mainLogo from "../../assets/RestDash/mainLogo.svg";
import { Link, useNavigate } from "react-router-dom";
import DashBoardFooter from "../../Components/DashBoardFooter/DashBoardFooter";
import { useState } from "react";
import { userLogin } from "../../utilies/api";
import { notifyError, notifySuccess } from "../../Components/toasters";
import { ToastContainer } from "react-toastify";
import { FaEye, FaEyeSlash } from "react-icons/fa"; // Import icons for showing/hiding password

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility
  const [isLoading, setIsLoading] = useState(false);

  const togglePasswordVisibility = () => setShowPassword(!showPassword);

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      const response = await userLogin({
        email,
        password,
      });
      notifySuccess(response.data.message);
      setEmail("");
      setPassword("");
      setTimeout(() => {
        navigate("/dashBoard");
      }, 1700);
      localStorage.setItem("admin_token", response.data.token);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      notifyError(error.response.data.message);
    }
  };

  return (
    <div className="login">
      <Container>
        <ToastContainer />
        <img className="login-logo" src={mainLogo} alt="logo" />
        <div className="side-form">
          <div className="side-form-section">
            <h1 className="mb-3">Login</h1>
            <p className="mb-4">
              Get started to access your restaurant management tools.
            </p>
            <form className="form" onSubmit={handleLogin}>
              <label className="mb-2" htmlFor="email">
                Email Address
              </label>
              <input
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                className="form-input mb-3"
                type="email"
                name="email"
                id="email"
                placeholder="Enter Your Email Address"
              />
              <label className="mb-2" htmlFor="password">
                Password
              </label>
              <div
                className="password-wrapper mb-3"
                style={{ position: "relative" }}
              >
                <input
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  className="form-input"
                  type={showPassword ? "text" : "password"}
                  name="password"
                  id="password"
                  placeholder="Enter Your Password"
                />
                <span
                  style={{
                    position: "absolute",
                    right: "15px",
                    top: "50%",
                    transform: "translateY(-50%)",
                    color: "#415FAC",
                    cursor: "pointer",
                  }}
                  className="password-toggle-icon"
                  onClick={togglePasswordVisibility}
                >
                  {showPassword ? (
                    <FaEyeSlash style={{ fontSize: "20px" }} />
                  ) : (
                    <FaEye style={{ fontSize: "20px" }} />
                  )}
                </span>
              </div>
              <div className="forget-link mb-2" style={{ textAlign: "right" }}>
                <Link to="/forgetPassword" className="forget mb-3">
                  Forget Password?
                </Link>
              </div>
              <button className="login-button" type="submit">
                {isLoading ? <Spinner /> : "Login"}
              </button>
            </form>
          </div>
        </div>
        <div className="text-center mt-3">
          <DashBoardFooter title={"© 2024 SmartServe"} />
        </div>
      </Container>
    </div>
  );
};

export default Login;
