import React from "react";
import "./voiceBotHome.css";
import PaginationComponent from "../../Components/PaginationComponent/PaginationComponent";
import DashBoardFooter from "../../Components/DashBoardFooter/DashBoardFooter";
import RestDashNav from "../../Components/RestDashNav/RestDashNav";
import { Container, Table } from "react-bootstrap";
import SearchFilter from "../../Components/SearchFilter/SearchFilter";
import eye_icon from "../../assets/RestDash/tickets/eye.svg";
import edit_icon from "../../assets/RestDash/edit_icon.png";
import { useNavigate } from "react-router-dom";

const VoiceBotHome = () => {
  const navigate = useNavigate();
  return (
    <>
      <RestDashNav title={"Voice bot"} subTitle={"30 Total Subscribers"} />
      <Container>
        <SearchFilter
          filterConfig={[
            {
              name: "SubscriberID",
              label: "Subscriber ID",
              type: "text",
              placeholder: "Subscriber ID",
            },
            {
              name: "SubscriberDetails",
              label: "Subscriber Details",
              type: "select",
              options: [
                { value: "", label: "Subscriber Details" },
                { value: "Technical Support	", label: "Technical Support" },
                { value: "Call Issue", label: "Call Issue" },
                { value: "Billing Issue", label: "Billing Issue" },
              ],
            },
            {
              name: "CreatedAt",
              label: "Subscribtion Date & Time",
              type: "datetime-local",
            },

            {
              name: "Subscriber Status",
              type: "select",
              options: [
                { value: "", label: "Subscriber Status" },
                { value: "Open", label: "Open" },
                { value: "Closed", label: "Closed" },
                { value: "In Progress", label: "In Progress" },
              ],
            },
          ]}
          // onFilterChange={handleFilterChange}
          // onClearFilters={handleClearFilters}
        />
        <div className="d-flex align-align-items-end justify-content-end mb-4">
          <button
            onClick={() => navigate("/voice-add-new-subscriber")}
            className="primary-btn"
          >
            Add New Subscriber
          </button>
        </div>
        <div className="container-section">
          <Table
            responsive
            borderless
            style={{ textWrap: "nowrap", fontWeight: "bold" }}
          >
            <thead>
              <tr>
                <th style={{ paddingBottom: "25px" }}>Subscriber ID</th>
                <th style={{ paddingBottom: "25px" }}>Subscriber Details</th>
                <th style={{ paddingBottom: "25px" }}>
                  Subscription start Date
                </th>
                <th style={{ paddingBottom: "25px" }}>Subscription End Date</th>
                <th style={{ paddingBottom: "25px" }}>Subscription Status</th>
                <th style={{ paddingBottom: "25px" }}>Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td
                  style={{
                    color: "#415FAC",
                    fontSize: "16px",
                    cursor: "pointer",
                  }}
                >
                  12344566
                </td>
                <td>
                  <h3 style={{ fontSize: "16px", fontWeight: "bold" }}>
                    Cobber Cooperate
                  </h3>
                  <h6 style={{ fontSize: "10px", fontWeight: "bold" }}>
                    CobberCooperate@gmail.com
                  </h6>
                </td>
                <td>12 Feb 2024</td>
                <td>12 Feb 2024</td>
                <td>
                  <span
                    style={{
                      backgroundColor: "#129D54",
                      color: "white",
                      height: "25px",
                      width: "95px",
                      display: "inline-block",
                      textAlign: "center",
                      borderRadius: "100px",
                      fontSize: "14px",
                    }}
                  >
                    Active
                  </span>
                </td>
                <td>
                  <div className="d-flex align-items-center gap-2 justify-content-flex-start h-100">
                    <img
                      onClick={() => navigate("/voice-edit-subscriber")}
                      style={{ cursor: "pointer" }}
                      src={edit_icon}
                      alt="icon"
                    />
                    <img
                      style={{ cursor: "pointer" }}
                      src={eye_icon}
                      alt="icon"
                      onClick={() => navigate("/voice-subscriber-details")}
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    color: "#415FAC",
                    fontSize: "16px",
                    cursor: "pointer",
                  }}
                >
                  12344566
                </td>
                <td>
                  <h3 style={{ fontSize: "16px", fontWeight: "bold" }}>
                    Cobber Cooperate
                  </h3>
                  <h6 style={{ fontSize: "10px", fontWeight: "bold" }}>
                    CobberCooperate@gmail.com
                  </h6>
                </td>
                <td>12 Feb 2024</td>
                <td>12 Feb 2024</td>
                <td>
                  <span
                    style={{
                      backgroundColor: "#FF3434",
                      color: "white",
                      height: "25px",
                      width: "95px",
                      display: "inline-block",
                      textAlign: "center",
                      borderRadius: "100px",
                      fontSize: "14px",
                    }}
                  >
                    Expired
                  </span>
                </td>
                <td>
                  <div className="d-flex align-items-center gap-2 justify-content-flex-start h-100">
                    <img
                      onClick={() => navigate("/voice-edit-subscriber")}
                      style={{ cursor: "pointer" }}
                      src={edit_icon}
                      alt="icon"
                    />
                    <img
                      style={{ cursor: "pointer" }}
                      src={eye_icon}
                      alt="icon"
                      onClick={() => navigate("/voice-subscriber-details")}
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    color: "#415FAC",
                    fontSize: "16px",
                    cursor: "pointer",
                  }}
                >
                  12344566
                </td>
                <td>
                  <h3 style={{ fontSize: "16px", fontWeight: "bold" }}>
                    Cobber Cooperate
                  </h3>
                  <h6 style={{ fontSize: "10px", fontWeight: "bold" }}>
                    CobberCooperate@gmail.com
                  </h6>
                </td>
                <td>12 Feb 2024</td>
                <td>12 Feb 2024</td>
                <td>
                  <span
                    style={{
                      backgroundColor: "#129D54",
                      color: "white",
                      height: "25px",
                      width: "95px",
                      display: "inline-block",
                      textAlign: "center",
                      borderRadius: "100px",
                      fontSize: "14px",
                    }}
                  >
                    Active
                  </span>
                </td>
                <td>
                  <div className="d-flex align-items-center gap-2 justify-content-flex-start h-100">
                    <img
                      onClick={() => navigate("/voice-edit-subscriber")}
                      style={{ cursor: "pointer" }}
                      src={edit_icon}
                      alt="icon"
                    />
                    <img
                      style={{ cursor: "pointer" }}
                      src={eye_icon}
                      alt="icon"
                      onClick={() => navigate("/voice-subscriber-details")}
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    color: "#415FAC",
                    fontSize: "16px",
                    cursor: "pointer",
                  }}
                >
                  12344566
                </td>
                <td>
                  <h3 style={{ fontSize: "16px", fontWeight: "bold" }}>
                    Cobber Cooperate
                  </h3>
                  <h6 style={{ fontSize: "10px", fontWeight: "bold" }}>
                    CobberCooperate@gmail.com
                  </h6>
                </td>
                <td>12 Feb 2024</td>
                <td>12 Feb 2024</td>
                <td>
                  <span
                    style={{
                      backgroundColor: "#FF3434",
                      color: "white",
                      height: "25px",
                      width: "95px",
                      display: "inline-block",
                      textAlign: "center",
                      borderRadius: "100px",
                      fontSize: "14px",
                    }}
                  >
                    Expired
                  </span>
                </td>
                <td>
                  <div className="d-flex align-items-center gap-2 justify-content-flex-start h-100">
                    <img
                      onClick={() => navigate("/voice-edit-subscriber")}
                      style={{ cursor: "pointer" }}
                      src={edit_icon}
                      alt="icon"
                    />
                    <img
                      style={{ cursor: "pointer" }}
                      src={eye_icon}
                      alt="icon"
                      onClick={() => navigate("/voice-subscriber-details")}
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    color: "#415FAC",
                    fontSize: "16px",
                    cursor: "pointer",
                  }}
                >
                  12344566
                </td>
                <td>
                  <h3 style={{ fontSize: "16px", fontWeight: "bold" }}>
                    Cobber Cooperate
                  </h3>
                  <h6 style={{ fontSize: "10px", fontWeight: "bold" }}>
                    CobberCooperate@gmail.com
                  </h6>
                </td>
                <td>12 Feb 2024</td>
                <td>12 Feb 2024</td>
                <td>
                  <span
                    style={{
                      backgroundColor: "#129D54",
                      color: "white",
                      height: "25px",
                      width: "95px",
                      display: "inline-block",
                      textAlign: "center",
                      borderRadius: "100px",
                      fontSize: "14px",
                    }}
                  >
                    Active
                  </span>
                </td>
                <td>
                  <div className="d-flex align-items-center gap-2 justify-content-flex-start h-100">
                    <img
                      onClick={() => navigate("/voice-edit-subscriber")}
                      style={{ cursor: "pointer" }}
                      src={edit_icon}
                      alt="icon"
                    />
                    <img
                      style={{ cursor: "pointer" }}
                      src={eye_icon}
                      alt="icon"
                      onClick={() => navigate("/voice-subscriber-details")}
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    color: "#415FAC",
                    fontSize: "16px",
                    cursor: "pointer",
                  }}
                >
                  12344566
                </td>
                <td>
                  <h3 style={{ fontSize: "16px", fontWeight: "bold" }}>
                    Cobber Cooperate
                  </h3>
                  <h6 style={{ fontSize: "10px", fontWeight: "bold" }}>
                    CobberCooperate@gmail.com
                  </h6>
                </td>
                <td>12 Feb 2024</td>
                <td>12 Feb 2024</td>
                <td>
                  <span
                    style={{
                      backgroundColor: "#FF3434",
                      color: "white",
                      height: "25px",
                      width: "95px",
                      display: "inline-block",
                      textAlign: "center",
                      borderRadius: "100px",
                      fontSize: "14px",
                    }}
                  >
                    Expired
                  </span>
                </td>
                <td>
                  <div className="d-flex align-items-center gap-2 justify-content-flex-start h-100">
                    <img
                      onClick={() => navigate("/voice-edit-subscriber")}
                      style={{ cursor: "pointer" }}
                      src={edit_icon}
                      alt="icon"
                    />
                    <img
                      style={{ cursor: "pointer" }}
                      src={eye_icon}
                      alt="icon"
                      onClick={() => navigate("/voice-subscriber-details")}
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    color: "#415FAC",
                    fontSize: "16px",
                    cursor: "pointer",
                  }}
                >
                  12344566
                </td>
                <td>
                  <h3 style={{ fontSize: "16px", fontWeight: "bold" }}>
                    Cobber Cooperate
                  </h3>
                  <h6 style={{ fontSize: "10px", fontWeight: "bold" }}>
                    CobberCooperate@gmail.com
                  </h6>
                </td>
                <td>12 Feb 2024</td>
                <td>12 Feb 2024</td>
                <td>
                  <span
                    style={{
                      backgroundColor: "#129D54",
                      color: "white",
                      height: "25px",
                      width: "95px",
                      display: "inline-block",
                      textAlign: "center",
                      borderRadius: "100px",
                      fontSize: "14px",
                    }}
                  >
                    Active
                  </span>
                </td>
                <td>
                  <div className="d-flex align-items-center gap-2 justify-content-flex-start h-100">
                    <img
                      onClick={() => navigate("/voice-edit-subscriber")}
                      style={{ cursor: "pointer" }}
                      src={edit_icon}
                      alt="icon"
                    />
                    <img
                      style={{ cursor: "pointer" }}
                      src={eye_icon}
                      alt="icon"
                      onClick={() => navigate("/voice-subscriber-details")}
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    color: "#415FAC",
                    fontSize: "16px",
                    cursor: "pointer",
                  }}
                >
                  12344566
                </td>
                <td>
                  <h3 style={{ fontSize: "16px", fontWeight: "bold" }}>
                    Cobber Cooperate
                  </h3>
                  <h6 style={{ fontSize: "10px", fontWeight: "bold" }}>
                    CobberCooperate@gmail.com
                  </h6>
                </td>
                <td>12 Feb 2024</td>
                <td>12 Feb 2024</td>
                <td>
                  <span
                    style={{
                      backgroundColor: "#FF3434",
                      color: "white",
                      height: "25px",
                      width: "95px",
                      display: "inline-block",
                      textAlign: "center",
                      borderRadius: "100px",
                      fontSize: "14px",
                    }}
                  >
                    Expired
                  </span>
                </td>
                <td>
                  <div className="d-flex align-items-center gap-2 justify-content-flex-start h-100">
                    <img
                      onClick={() => navigate("/voice-edit-subscriber")}
                      style={{ cursor: "pointer" }}
                      src={edit_icon}
                      alt="icon"
                    />
                    <img
                      style={{ cursor: "pointer" }}
                      src={eye_icon}
                      alt="icon"
                      onClick={() => navigate("/voice-subscriber-details")}
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    color: "#415FAC",
                    fontSize: "16px",
                    cursor: "pointer",
                  }}
                >
                  12344566
                </td>
                <td>
                  <h3 style={{ fontSize: "16px", fontWeight: "bold" }}>
                    Cobber Cooperate
                  </h3>
                  <h6 style={{ fontSize: "10px", fontWeight: "bold" }}>
                    CobberCooperate@gmail.com
                  </h6>
                </td>
                <td>12 Feb 2024</td>
                <td>12 Feb 2024</td>
                <td>
                  <span
                    style={{
                      backgroundColor: "#129D54",
                      color: "white",
                      height: "25px",
                      width: "95px",
                      display: "inline-block",
                      textAlign: "center",
                      borderRadius: "100px",
                      fontSize: "14px",
                    }}
                  >
                    Active
                  </span>
                </td>
                <td>
                  <div className="d-flex align-items-center gap-2 justify-content-flex-start h-100">
                    <img
                      onClick={() => navigate("/voice-edit-subscriber")}
                      style={{ cursor: "pointer" }}
                      src={edit_icon}
                      alt="icon"
                    />
                    <img
                      style={{ cursor: "pointer" }}
                      src={eye_icon}
                      alt="icon"
                      onClick={() => navigate("/voice-subscriber-details")}
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    color: "#415FAC",
                    fontSize: "16px",
                    cursor: "pointer",
                  }}
                >
                  12344566
                </td>
                <td>
                  <h3 style={{ fontSize: "16px", fontWeight: "bold" }}>
                    Cobber Cooperate
                  </h3>
                  <h6 style={{ fontSize: "10px", fontWeight: "bold" }}>
                    CobberCooperate@gmail.com
                  </h6>
                </td>
                <td>12 Feb 2024</td>
                <td>12 Feb 2024</td>
                <td>
                  <span
                    style={{
                      backgroundColor: "#FF3434",
                      color: "white",
                      height: "25px",
                      width: "95px",
                      display: "inline-block",
                      textAlign: "center",
                      borderRadius: "100px",
                      fontSize: "14px",
                    }}
                  >
                    Expired
                  </span>
                </td>
                <td>
                  <div className="d-flex align-items-center gap-2 justify-content-flex-start h-100">
                    <img
                      onClick={() => navigate("/voice-edit-subscriber")}
                      style={{ cursor: "pointer" }}
                      src={edit_icon}
                      alt="icon"
                    />
                    <img
                      style={{ cursor: "pointer" }}
                      src={eye_icon}
                      alt="icon"
                      onClick={() => navigate("/voice-subscriber-details")}
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </Table>
          <PaginationComponent
            currentPage={1}
            totalPages={5}
            // handleNextPage={handleNextPage}
            // handlePrevPage={handlePrevPage}
            hasNextPage={true}
            hasPreviousPage={false}
          />
        </div>
        <div className="mt-5 mb-3">
          <DashBoardFooter title={"Powered by SmartServe"} />
        </div>
      </Container>
    </>
  );
};

export default VoiceBotHome;
