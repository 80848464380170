import "./subscriberDetails.css";
import { Container, Table } from "react-bootstrap";
import RestDashNav from "../../Components/RestDashNav/RestDashNav";
import edit_icon from "../../assets/RestDash/edit_icon.png";
import delete_icon from "../../assets/RestDash/menu/delete_icon.png";
import person from "../../assets/RestDash/person.svg";
import eye_icon from "../../assets/RestDash/tickets/eye.svg";
import return_icon from "../../assets/RestDash/tickets/return.svg";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { calls } from "../../utilies/calls";

const SubscriberDetails = () => {
  const navigate = useNavigate();
  const [isToggled, setIsToggled] = useState(true);

  const handleToggle = () => {
    setIsToggled(!isToggled);
  };
  const [filters, setFilters] = useState({
    callID: "",
    callerName: "",
    callDate: "",
    callType: "",
    status: "",
    by: "",
  });
  const filteredcalls = calls.filter((call) => {
    return (
      (filters.callID === "" ||
        call.callID.toString().includes(filters.callID)) &&
      (filters.callerName === "" ||
        call.callerName
          .toLowerCase()
          .includes(filters.callerName.toLowerCase())) &&
      (filters.callDate === "" || call.callDate.includes(filters.callDate)) &&
      (filters.callType === "" || call.callType === filters.callType) &&
      (filters.status === "" || call.status === filters.status) &&
      (filters.by === "" || call.by === filters.by)
    );
  });
  return (
    <>
      <RestDashNav
        title={"Subscriber Details"}
        redirect_path={"/real-time-agents"}
      />
      <Container>
        <div className="ticket-details">
          <div className="d-flex justify-content-between align-items-center mb-2">
            <div className="d-flex flex-column">
              <span
                style={{
                  color: "#636363",
                  fontSize: "16px",
                  fontWeight: "500",
                }}
              >
                Subscriber ID
              </span>
              <b
                style={{
                  color: "#415FAC",
                  fontSize: "24px",
                  fontWeight: "bold",
                }}
              >
                123456
              </b>
            </div>
            <span
              style={{
                backgroundColor: "#129D54",
                color: "white",
                height: "30px",
                lineHeight: "30px",
                width: "95px",
                display: "inline-block",
                textAlign: "center",
                borderRadius: "100px",
                fontSize: "14px",
                fontWeight: "bold",
              }}
            >
              Active
            </span>
          </div>
          <div className="hr" />
          <h3>Subscriber Details</h3>
          <div className="d-flex justify-content-between align-items-center mb-2">
            <span
              style={{ color: "#636363", fontSize: "16px", fontWeight: "500" }}
            >
              Subscriber Name
            </span>
            <b>John Doe</b>
          </div>
          <div className="d-flex justify-content-between align-items-center mb-2">
            <span
              style={{ color: "#636363", fontSize: "16px", fontWeight: "500" }}
            >
              Caller Number
            </span>
            <b>+1 234 567 890</b>
          </div>
          <div className="d-flex justify-content-between align-items-center mb-2">
            <span
              style={{ color: "#636363", fontSize: "16px", fontWeight: "500" }}
            >
              Call Date & Time
            </span>
            <b>2023-07-10 14:05</b>
          </div>
          <div className="d-flex justify-content-between align-items-center mb-2">
            <span
              style={{ color: "#636363", fontSize: "16px", fontWeight: "500" }}
            >
              Duration
            </span>
            <b>5 Mins</b>
          </div>
          <div className="hr" />
          <h3>Subscriber Details</h3>
          <div className="d-flex justify-content-between align-items-center mb-2">
            <span
              style={{ color: "#636363", fontSize: "16px", fontWeight: "500" }}
            >
              Subscription Date
            </span>
            <b>2024-07-01</b>
          </div>
          <div className="d-flex justify-content-between align-items-center mb-2">
            <span
              style={{ color: "#636363", fontSize: "16px", fontWeight: "500" }}
            >
              Usage Duration
            </span>
            <b className="text-danger">120 min</b>
          </div>
          <div className="d-flex justify-content-between align-items-center mb-2">
            <span
              style={{ color: "#636363", fontSize: "16px", fontWeight: "500" }}
            >
              Remaining Duration:
            </span>
            <b className="text-success">30 min</b>
          </div>
          <div className="hr"></div>
          <h3>Assigned Agents</h3>
          <div
            style={{
              backgroundColor: "rgba(226, 226, 226, 0.9333)",
              borderRadius: "15px",
              padding: "15px",
            }}
          >
            <Table
              responsive
              borderless
              bgcolor="transparent"
              style={{
                textWrap: "nowrap",
                fontWeight: "bold",
              }}
            >
              <thead>
                <tr>
                  <th style={{ paddingBottom: "25px" }}>User Details</th>
                  <th style={{ paddingBottom: "25px" }}>Role</th>
                  <th style={{ paddingBottom: "25px" }}>Mobile Number</th>
                  <th style={{ paddingBottom: "25px" }}>Status</th>
                  <th style={{ paddingBottom: "25px" }}>Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div className="d-flex align-items-center gap-2">
                      <img width={50} height={50} src={person} alt="person" />
                      <div>
                        <h3
                          style={{
                            color: "#415FAC",
                            fontSize: "16px",
                            cursor: "pointer",
                            marginBottom: "5px",
                          }}
                        >
                          Ahmed Mohamed
                        </h3>
                        <h6 style={{ fontSize: "13px" }}>
                          ahmed.mohamed@gmail.com
                        </h6>
                      </div>
                    </div>
                  </td>
                  <td>Agent</td>
                  <td>+962 7 9707 3022</td>
                  <td>
                    <div
                      className={`toggle-switch ${isToggled ? "active" : ""}`}
                      onClick={handleToggle}
                    >
                      <div className="switch-label">
                        {isToggled ? "Active" : "Inactive"}
                      </div>
                      <div className="switch-handle"></div>
                    </div>
                  </td>
                  <td>
                    <div className="d-flex align-items-center gap-2 justify-content-flex-start h-100">
                      <img
                        style={{ cursor: "pointer" }}
                        src={edit_icon}
                        alt="icon"
                        onClick={() => navigate("/ticketDetails")}
                      />
                      <img
                        style={{ cursor: "pointer" }}
                        src={delete_icon}
                        alt="icon"
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="d-flex align-items-center gap-2">
                      <img width={50} height={50} src={person} alt="person" />
                      <div>
                        <h3
                          style={{
                            color: "#415FAC",
                            fontSize: "16px",
                            cursor: "pointer",
                            marginBottom: "5px",
                          }}
                        >
                          Ahmed Mohamed
                        </h3>
                        <h6 style={{ fontSize: "13px" }}>
                          ahmed.mohamed@gmail.com
                        </h6>
                      </div>
                    </div>
                  </td>
                  <td>Agent</td>
                  <td>+962 7 9707 3022</td>
                  <td>
                    <div
                      className={`toggle-switch ${isToggled ? "active" : ""}`}
                      onClick={handleToggle}
                    >
                      <div className="switch-label">
                        {isToggled ? "Active" : "Inactive"}
                      </div>
                      <div className="switch-handle"></div>
                    </div>
                  </td>
                  <td>
                    <div className="d-flex align-items-center gap-2 justify-content-flex-start h-100">
                      <img
                        style={{ cursor: "pointer" }}
                        src={edit_icon}
                        alt="icon"
                        onClick={() => navigate("/ticketDetails")}
                      />
                      <img
                        style={{ cursor: "pointer" }}
                        src={delete_icon}
                        alt="icon"
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="d-flex align-items-center gap-2">
                      <img width={50} height={50} src={person} alt="person" />
                      <div>
                        <h3
                          style={{
                            color: "#415FAC",
                            fontSize: "16px",
                            cursor: "pointer",
                            marginBottom: "5px",
                          }}
                        >
                          Ahmed Mohamed
                        </h3>
                        <h6 style={{ fontSize: "13px" }}>
                          ahmed.mohamed@gmail.com
                        </h6>
                      </div>
                    </div>
                  </td>
                  <td>Agent</td>
                  <td>+962 7 9707 3022</td>
                  <td>
                    <div
                      className={`toggle-switch ${isToggled ? "active" : ""}`}
                      onClick={handleToggle}
                    >
                      <div className="switch-label">
                        {isToggled ? "Active" : "Inactive"}
                      </div>
                      <div className="switch-handle"></div>
                    </div>
                  </td>
                  <td>
                    <div className="d-flex align-items-center gap-2 justify-content-flex-start h-100">
                      <img
                        style={{ cursor: "pointer" }}
                        src={edit_icon}
                        alt="icon"
                        onClick={() => navigate("/ticketDetails")}
                      />
                      <img
                        style={{ cursor: "pointer" }}
                        src={delete_icon}
                        alt="icon"
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="d-flex align-items-center gap-2">
                      <img width={50} height={50} src={person} alt="person" />
                      <div>
                        <h3
                          style={{
                            color: "#415FAC",
                            fontSize: "16px",
                            cursor: "pointer",
                            marginBottom: "5px",
                          }}
                        >
                          Ahmed Mohamed
                        </h3>
                        <h6 style={{ fontSize: "13px" }}>
                          ahmed.mohamed@gmail.com
                        </h6>
                      </div>
                    </div>
                  </td>
                  <td>Agent</td>
                  <td>+962 7 9707 3022</td>
                  <td>
                    <div
                      className={`toggle-switch ${isToggled ? "active" : ""}`}
                      onClick={handleToggle}
                    >
                      <div className="switch-label">
                        {isToggled ? "Active" : "Inactive"}
                      </div>
                      <div className="switch-handle"></div>
                    </div>
                  </td>
                  <td>
                    <div className="d-flex align-items-center gap-2 justify-content-flex-start h-100">
                      <img
                        style={{ cursor: "pointer" }}
                        src={edit_icon}
                        alt="icon"
                        onClick={() => navigate("/ticketDetails")}
                      />
                      <img
                        style={{ cursor: "pointer" }}
                        src={delete_icon}
                        alt="icon"
                      />
                    </div>
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
          <div className="hr"></div>
          <h3>Call Logs</h3>
          <div
            style={{
              backgroundColor: "rgba(226, 226, 226, 0.9333)",
              borderRadius: "15px",
              padding: "15px",
            }}
          >
            <Table className="table m-auto table-borderless ">
              <thead className="header-table">
                <tr>
                  <th>Call ID</th>
                  <th>Caller Name</th>
                  <th>Caller Number</th>
                  <th>Call Date & Time</th>
                  <th>Duration</th>
                  <th>Call Type</th>
                  <th>Status</th>
                  <th>Handled by</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {filteredcalls.map((call, index) => (
                  <tr key={index}>
                    <td className="call-id">{call.callID}</td>
                    <td className="caller-name">{call.callerName}</td>
                    <td className="caller-number">{call.callerNumber}</td>
                    <td className="caller-date">{call.callDate}</td>
                    <td className="call-duration">{call.callDuration}</td>
                    <td className={`call-type ${call.callType.toLowerCase()}`}>
                      {call.callType}
                    </td>
                    <td
                      className={`status d-flex align-items-center justify-content-around ${call.status.toLowerCase()}`}
                    >
                      {call.status}
                    </td>
                    <td className="handled-by">{call.by}</td>
                    <td className="action">
                      <a
                        href={
                          call.by === "AI" ? "/calldetailsai" : "/calldetails"
                        }
                      >
                        <img src={eye_icon} alt="View" />
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
          <div className="hr"></div>
          <h3>Tickets</h3>
          <div
            style={{
              backgroundColor: "rgba(226, 226, 226, 0.9333)",
              borderRadius: "15px",
              padding: "15px",
            }}
          >
            <Table
              responsive
              borderless
              style={{ textWrap: "nowrap", fontWeight: "bold" }}
            >
              <thead>
                <tr>
                  <th style={{ paddingBottom: "25px" }}>Ticket ID</th>
                  <th style={{ paddingBottom: "25px" }}>Subject</th>
                  <th style={{ paddingBottom: "25px" }}>Type</th>
                  <th style={{ paddingBottom: "25px" }}>Created By</th>
                  <th style={{ paddingBottom: "25px" }}>Date Created</th>
                  <th style={{ paddingBottom: "25px" }}>Status</th>
                  <th style={{ paddingBottom: "25px" }}>Last Updated</th>
                  <th style={{ paddingBottom: "25px" }}>Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td
                    style={{
                      color: "#415FAC",
                      fontSize: "16px",
                      cursor: "pointer",
                    }}
                  >
                    123456
                  </td>
                  <td>Technical Support</td>
                  <td>Technical Support</td>
                  <td>John Doe</td>
                  <td>2023-07-10 14:05</td>
                  <td>
                    <span
                      style={{
                        backgroundColor: "#F4A223",
                        color: "white",
                        height: "25px",
                        width: "95px",
                        display: "inline-block",
                        textAlign: "center",
                        borderRadius: "100px",
                        fontSize: "14px",
                      }}
                    >
                      In Progress
                    </span>
                  </td>
                  <td>2023-07-10 11:00</td>
                  <td>
                    <div className="d-flex align-items-center gap-2 justify-content-flex-start h-100">
                      <img
                        style={{ cursor: "pointer" }}
                        src={eye_icon}
                        alt="icon"
                        onClick={() => navigate("/ticketDetails")}
                      />
                      <img
                        style={{ cursor: "pointer" }}
                        src={return_icon}
                        alt="icon"
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      color: "#415FAC",
                      fontSize: "16px",
                      cursor: "pointer",
                    }}
                  >
                    123456
                  </td>
                  <td>Call Issue</td>
                  <td>Call Issue</td>
                  <td>John Doe</td>
                  <td>2023-07-10 14:05</td>
                  <td>
                    <span
                      style={{
                        backgroundColor: "#129D54",
                        color: "white",
                        height: "25px",
                        width: "95px",
                        display: "inline-block",
                        textAlign: "center",
                        borderRadius: "100px",
                        fontSize: "14px",
                      }}
                    >
                      Open
                    </span>
                  </td>
                  <td>2023-07-10 11:00</td>
                  <td>
                    <div className="d-flex align-items-center gap-2 justify-content-flex-start h-100">
                      <img
                        style={{ cursor: "pointer" }}
                        src={eye_icon}
                        alt="icon"
                      />
                      <img
                        style={{ cursor: "pointer" }}
                        src={return_icon}
                        alt="icon"
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      color: "#415FAC",
                      fontSize: "16px",
                      cursor: "pointer",
                    }}
                  >
                    123456
                  </td>
                  <td>Billing Issue</td>
                  <td>Billing Issue</td>
                  <td>John Doe</td>
                  <td>2023-07-10 14:05</td>
                  <td>
                    <span
                      style={{
                        backgroundColor: "#FF3434",
                        color: "white",
                        height: "25px",
                        width: "95px",
                        display: "inline-block",
                        textAlign: "center",
                        borderRadius: "100px",
                        fontSize: "14px",
                      }}
                    >
                      Closed
                    </span>
                  </td>
                  <td>2023-07-10 11:00</td>
                  <td>
                    <div className="d-flex align-items-center gap-2 justify-content-flex-start h-100">
                      <img
                        style={{ cursor: "pointer" }}
                        src={eye_icon}
                        alt="icon"
                      />
                      <img
                        style={{ cursor: "pointer" }}
                        src={return_icon}
                        alt="icon"
                      />
                    </div>
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </div>
      </Container>

      <div>
        <p className="mb-3 mt-5 mx-4 text-secondary fw-bold">
          Powered by SmartServe
        </p>
      </div>
    </>
  );
};

export default SubscriberDetails;
