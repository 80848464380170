import React, { useEffect, useState } from "react";
import "./profile.css";
import RestDashNav from "../../Components/RestDashNav/RestDashNav";
import { Col, Row } from "react-bootstrap";
import DashBoardFooter from "../../Components/DashBoardFooter/DashBoardFooter";
import { jwtDecode } from "jwt-decode";
import { adminUpdate, getAdminData } from "../../utilies/api";
import { notifyError, notifySuccess } from "../../Components/toasters";
import { ToastContainer } from "react-toastify";
import LoaderSpinner from "../../Components/LoaderSpinner";

const Profile = () => {
  const [isLoading, setIsLoading] = useState(true);
  const token = localStorage.getItem("admin_token");
  const [userId, setUserId] = useState();
  const [profile, setProfile] = useState({
    name: "",
    email: "",
    phone: "",
    password: "",
  });
  const handleChange = (event) => {
    const { target } = event;
    setProfile((prevState) => ({
      ...prevState,
      [target.name]: target.value,
    }));
  };
  const handleChangePhone = (event) => {
    const newValue = event.target.value;

    // Remove any non-numeric characters
    const numericValue = newValue.replace(/\D/g, "");

    setProfile((prevState) => ({
      ...prevState,
      phone: numericValue,
    }));
  };
  // update profile
  const handleSubmitAndRedirect = async (e) => {
    e.preventDefault();
    const data = {
      id: +userId,
      name: profile.name,
      phone: profile.phone,
    };
    try {
      if (profile.name !== "" && profile.phone !== "") {
        const response = await adminUpdate(data);
        console.log(response);
        notifySuccess(response.data.message);
      } else {
        notifyError("Please Fill All The Fields");
      }
    } catch (error) {
      notifyError(error.response.data.message);
    }
  };

  useEffect(() => {
    if (token) {
      setUserId(jwtDecode(token)?.agent?.id);
      (async () => {
        try {
          const { data } = await getAdminData(userId);
          setProfile({
            name: data[0].name,
            email: data[0].email,
            phone: data[0].phone,
            password: data[0].password,
          });
          setIsLoading(false);
        } catch (error) {
          setIsLoading(false);
          notifyError(error.response.data.message);
        }
      })();
    }
  }, [token, userId]);

  return (
    <>
      <RestDashNav
        title={"Profile Settings"}
        subTitle={"Update Your Profile"}
      />
      <div className="my-orders">
        <ToastContainer />
        <div className="table-container" style={{ height: "720px" }}>
          {isLoading ? (
            <LoaderSpinner />
          ) : (
            <>
              <Row className="mb-4">
                <Col xs={12} md={12} lg={4} className="mb-4">
                  <label
                    style={{
                      marginBottom: "15px",
                      fontSize: "18px",
                      fontWeight: "bold",
                    }}
                    htmlFor="name"
                  >
                    Full Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    onChange={handleChange}
                    name="name"
                    value={profile.name}
                  />
                </Col>
                <Col xs={12} md={12} lg={4} className="mb-4">
                  <label
                    style={{
                      marginBottom: "15px",
                      fontSize: "18px",
                      fontWeight: "bold",
                    }}
                    htmlFor="email"
                  >
                    Email
                  </label>
                  <input
                    readOnly
                    type="email"
                    className="form-control"
                    id="email"
                    onChange={handleChange}
                    name="email"
                    value={profile.email}
                  />
                </Col>
                <Col xs={12} md={12} lg={4} className="mb-4">
                  <label
                    style={{
                      marginBottom: "15px",
                      fontSize: "18px",
                      fontWeight: "bold",
                    }}
                    htmlFor="phoneNumber"
                  >
                    Mobile Number
                  </label>
                  <input
                    type="tel"
                    className="form-control"
                    id="phoneNumber"
                    onChange={handleChangePhone}
                    name="phone"
                    value={profile.phone}
                  />
                </Col>
                <Col xs={12} md={12} lg={6} className="mb-4">
                  <label
                    style={{
                      marginBottom: "15px",
                      fontSize: "18px",
                      fontWeight: "bold",
                    }}
                    htmlFor="password"
                  >
                    Password
                  </label>
                  <input
                    readOnly
                    type="password"
                    className="form-control"
                    id="password"
                    placeholder="********"
                    onChange={handleChange}
                    name="password"
                    value={profile.password}
                  />
                </Col>
                <Col xs={12} md={12} lg={6}>
                  <label
                    style={{
                      marginBottom: "15px",
                      fontSize: "18px",
                      fontWeight: "bold",
                    }}
                    htmlFor="cpassword"
                  >
                    Confirm Password
                  </label>
                  <input
                    readOnly
                    type="password"
                    className="form-control mb-5"
                    id="cpassword"
                    placeholder="********"
                    onChange={handleChange}
                    name="password"
                    value={profile.password}
                  />
                </Col>
              </Row>
              <div className="d-flex justify-content-end">
                <button
                  className="add-item-btn"
                  onClick={handleSubmitAndRedirect}
                >
                  Save
                </button>
              </div>
            </>
          )}
        </div>
        <DashBoardFooter />
      </div>
    </>
  );
};

export default Profile;
