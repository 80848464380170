import React from "react";
import { Form, InputGroup, Row, Col } from "react-bootstrap";
import "./searchFilter.css"; // Import styles

const SearchFilter = ({ filterConfig, onFilterChange, onClearFilters }) => {
  const handleChange = (e) => {
    const { name, value } = e.target;
    onFilterChange(name, value);
  };

  const handleSelect = (name, value) => {
    onFilterChange(name, value);
  };

  // Split filterConfig into two groups for two rows
  const row1Filters = filterConfig;
  // const row2Filters = filterConfig.slice(3, 6);

  return (
    <div className="search-filter mb-4">
      <div className="d-flex align-items-center justify-content-between my-3">
        <h5>Search & Filter</h5>
        {/* <a href={"/#"} onClick={onClearFilters} className="clear-link">
          Clear
        </a> */}
      </div>
      <Form>
        <Row className="mb-2">
          {row1Filters.map((filter, index) => (
            <Col key={index} md={4} lg={4} xl={3} className="mb-3">
              <Form.Group controlId={filter.name}>
                {filter.type === "select" ? (
                  <Form.Select
                    name={filter.name}
                    onChange={(e) => handleSelect(filter.name, e.target.value)}
                    defaultValue={filter.defaultValue || ""}
                  >
                    {filter.options.map((option, i) => (
                      <option key={i} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Form.Select>
                ) : (
                  <InputGroup>
                    <Form.Control
                      type={filter.type}
                      name={filter.name}
                      placeholder={filter.placeholder}
                      onChange={handleChange}
                    />
                  </InputGroup>
                )}
              </Form.Group>
            </Col>
          ))}
        </Row>

        {/* <Row className="mb-3">
          {row2Filters.map((filter, index) => (
            <Col key={index} md={4}>
              <Form.Group controlId={filter.name}>
                {filter.type === "select" ? (
                  <Form.Select
                    name={filter.name}
                    onChange={(e) => handleSelect(filter.name, e.target.value)}
                    defaultValue={filter.defaultValue || ""}
                  >
                    {filter.options.map((option, i) => (
                      <option key={i} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Form.Select>
                ) : (
                  <InputGroup>
                    <Form.Control
                      type={filter.type}
                      name={filter.name}
                      placeholder={filter.placeholder}
                      onChange={handleChange}
                    />
                  </InputGroup>
                )}
              </Form.Group>
            </Col>
          ))}
        </Row> */}
      </Form>
    </div>
  );
};

export default SearchFilter;
