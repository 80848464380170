import RestDashNav from "../RestDashNav/RestDashNav";
import "./restaurantLists.css";
import { useEffect, useState } from "react";
import DashBoardFooter from "../DashBoardFooter/DashBoardFooter";
import { Col, Row } from "react-bootstrap";
import person from "../../assets/RestDash/person.png";
import PaginationComponent from "../PaginationComponent/PaginationComponent";
import { getAllRestaurants } from "../../utilies/api";
import LoaderSpinner from "../LoaderSpinner";
import axios from "axios";
import edit from "../../assets/RestDash/edit_icon.png";
import plus from "../../assets/RestDash/plus.png";
import { useNavigate } from "react-router-dom";
import { formatDate1 } from "../Requests/Requests";
import { notifySuccess } from "../toasters";
import { ToastContainer } from "react-toastify";
import AddRestaurantAmount from "../AddRestaurantAmount";

const RestaurantLists = () => {
  const [show, setShow] = useState(false);
  const [showId, setShowId] = useState(0);
  const handleClose = () => setShow(false);
  const handleShow = (idx) => {
    setShow(true);
    setShowId(idx);
  };
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [allRestaurants, setAllRestaurants] = useState([]);
  const [id, setId] = useState("");
  const [restName, setRestName] = useState("");
  const [restStatus, setRestStatus] = useState("");
  const [creationDate, setCreationDate] = useState("");
  // pagination states
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [hasPreviousPage, setHasPreviousPage] = useState(false);
  const [total, setTotal] = useState(0);
  const getAllRestaurantsFun = async (id, name, status, createdAt, page) => {
    try {
      const result = await getAllRestaurants(id, name, status, createdAt, page);
      setAllRestaurants(result.data);
      setCurrentPage(parseInt(result.pagination.page));
      setTotalPages(result.pagination.totalPages);
      setHasNextPage(result.pagination.hasNextPage);
      setHasPreviousPage(result.pagination.hasPreviousPage);
      setTotal(result.pagination.totalRecords);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };
  const handleToggle = async (id) => {
    // Find the restaurant to update
    const updatedRestaurants = allRestaurants.map((rest) => {
      if (rest.id === id) {
        // Toggle the status
        return { ...rest, status: !rest.status };
      }
      return rest;
    });
    // Update the local state
    setAllRestaurants(updatedRestaurants);
    // Get the updated restaurant
    const updatedRestaurant = updatedRestaurants.find((rest) => rest.id === id);
    // Send the updated status to the API
    try {
      const response = await axios.put(
        `http://91.106.108.98:8080/restaurant/active`,
        {
          id: id,
          status: updatedRestaurant.status,
        }
      );
      notifySuccess(response.data.message);
    } catch (error) {
      console.error("Error updating restaurant status:", error);
    }
  };
  const handleNextPage = () => {
    if (hasNextPage) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (hasPreviousPage) {
      setCurrentPage(currentPage - 1);
    }
  };
  useEffect(() => {
    getAllRestaurantsFun(id, restName, restStatus, creationDate, currentPage);
  }, [id, restName, restStatus, creationDate, currentPage]);

  return (
    <>
      <RestDashNav
        title={"Restaurants List"}
        subTitle={`${total > 0 ? total : 0} Total Restaurants`}
        redirectPath={"/login"}
      />
      <ToastContainer />
      <div className="my-orders">
        <h1>Search & Filter</h1>
        <Row className="mb-3">
          <Col xs={12} md={4} lg={3}>
            <input
              type="number"
              min="0"
              className="form-control"
              placeholder="ID"
              value={id}
              onChange={(e) => setId(e.target.value)}
            />
          </Col>
          <Col xs={12} md={4} lg={3}>
            <input
              type="text"
              className="form-control"
              placeholder="Restaurant Name"
              value={restName}
              onChange={(e) => setRestName(e.target.value)}
            />
          </Col>
          <Col xs={12} md={4} lg={3}>
            <select
              className="form-select"
              defaultValue="Status"
              onChange={(e) => setRestStatus(e.target.value)}
            >
              <option disabled>Status</option>
              <option value="">All</option>
              <option value={true}>Active</option>
              <option value={false}>In Active</option>
            </select>
          </Col>
          <Col xs={12} md={4} lg={3}>
            <input
              type="date"
              className="form-control"
              placeholder="Creation Date"
              value={creationDate}
              onChange={(e) => setCreationDate(e.target.value)}
            />
          </Col>
          <Col xs={12} md={4} lg={9}></Col>
          <Col xs={12} md={4} lg={3}>
            <div className="d-flex justify-content-end mb-4">
              <button
                className="add-item-btn"
                onClick={() => navigate("/addRestaurant")}
              >
                Add New Restaurant
              </button>
            </div>
          </Col>
        </Row>
        {isLoading ? (
          <LoaderSpinner />
        ) : (
          <div
            className="table-container table-responsive text-nowrap text-capitalize"
            style={{ height: "70vh" }}
          >
            <table className="table table-borderless">
              <thead>
                <tr>
                  <th scope="col">Restaurant Details</th>
                  <th scope="col">Address</th>
                  <th scope="col">Amount Details</th>
                  <th scope="col">Number Of Orders</th>
                  <th scope="col">Status</th>
                  <th scope="col">Creation Date</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>
                {allRestaurants?.length > 0 ? (
                  allRestaurants.map((rest, idx) => (
                    <tr key={rest?.id}>
                      <td>
                        <div className="rest-info d-flex align-items-center gap-2">
                          <img
                            width="50"
                            height="50"
                            src={person}
                            alt="person"
                          />
                          <div className="rest-name">
                            <p className="text-primary">#{rest?.id}</p>
                            <p>{rest?.name}</p>
                            <p>{rest?.phone}</p>
                          </div>
                        </div>
                      </td>
                      <td>
                        <p className="fw-bold">{rest?.address}</p>
                      </td>
                      <td>
                        <div className="rest-info d-flex align-items-center gap-3">
                          <div className="rest-name">
                            <p className="text-success fw-bold">
                              {rest?.RestaurantSubscriptions[0]?.amount > 0
                                ? `${rest?.RestaurantSubscriptions[0]?.amount} JOD`
                                : "Free"}
                            </p>
                            <p className="text-danger fw-bold">
                              {" "}
                              {formatDate1(
                                rest?.RestaurantSubscriptions[0]?.endDate
                              )}
                            </p>
                          </div>
                          <img
                            onClick={() => handleShow(idx)}
                            className="plus"
                            src={plus}
                            alt="plus"
                          />
                          <AddRestaurantAmount
                            allRestaurants={allRestaurants}
                            idx={idx}
                            show={showId === idx && show}
                            handleClose={handleClose}
                            setIsLoading={setIsLoading}
                          />
                        </div>
                      </td>

                      <td>
                        <p className="fw-bold text-success">
                          {rest?.ordersCount}
                        </p>
                      </td>
                      <td>
                        <div className="rest-status">
                          <div className="form-switch">
                            <input
                              type="checkbox"
                              id="custom-switch"
                              className="form-check-input"
                              checked={rest?.status}
                              onChange={() => handleToggle(rest.id)}
                            />
                          </div>
                        </div>
                      </td>
                      <td>
                        <p className="fw-bold">
                          {formatDate1(rest?.createdAt)}
                        </p>
                      </td>
                      <td>
                        <img
                          onClick={() => navigate(`/editRestaurant/${rest.id}`)}
                          className="mx-2"
                          width={20}
                          height={20}
                          src={edit}
                          alt="edit"
                          style={{ cursor: "pointer" }}
                        />
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      style={{ fontWeight: "bold" }}
                      className="text-danger"
                      colSpan={6}
                    >
                      No Restaurants Found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        )}
        <PaginationComponent
          currentPage={currentPage}
          totalPages={totalPages}
          handleNextPage={handleNextPage}
          handlePrevPage={handlePrevPage}
          hasNextPage={hasNextPage}
          hasPreviousPage={hasPreviousPage}
        />
        <DashBoardFooter title={"Powered by SmartServe"} />
      </div>
    </>
  );
};

export default RestaurantLists;
