import { Col, Row } from "react-bootstrap";
import { Outlet } from "react-router-dom";
import RestDashSideMenu from "../Components/RestDashSideMenu/RestDashSideMenu";
import ScrollToTop from "../Components/ScrollToTop";

const RestDashboard = () => {
  return (
    <>
      <ScrollToTop />
      <div className="container-fluid">
        <Row>
          <Col xs={2} md={1} lg={1} className="p-0">
            <RestDashSideMenu />
          </Col>
          <Col xs={10} md={11} lg={11} className="p-0">
            <Outlet />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default RestDashboard;
