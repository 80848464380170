import "./ticketDetails.css";
import chat from "../../assets/RestDash/tickets/Chat.png";
import { Container } from "react-bootstrap";
import RestDashNav from "../../Components/RestDashNav/RestDashNav";
import { useState } from "react";
import ClosedTicketModal from "./ClosedTicketModal";

const TicketDetails = () => {
  const [status, setStatus] = useState(true);
  const [modalShow, setModalShow] = useState(false);

  return (
    <>
      <RestDashNav title={"Ticket Details"} redirect_path={"/tickets"} />
      <Container className="py-4">
        <div className="ticket-details">
          <div className="d-flex justify-content-between align-items-center mb-2">
            <div className="d-flex flex-column">
              <span
                style={{
                  color: "#636363",
                  fontSize: "16px",
                  fontWeight: "500",
                }}
              >
                Ticket ID
              </span>
              <b
                style={{
                  color: "#415FAC",
                  fontSize: "24px",
                  fontWeight: "bold",
                }}
              >
                1001
              </b>
            </div>
            <span
              style={{
                backgroundColor: status ? "#129D54" : "#FF3434",
                color: "white",
                height: "30px",
                lineHeight: "30px",
                width: "95px",
                display: "inline-block",
                textAlign: "center",
                borderRadius: "100px",
                fontSize: "14px",
                fontWeight: "bold",
              }}
            >
              {status ? "Opened" : "Closed"}
            </span>
          </div>
          <div className="d-flex justify-content-between align-items-center mb-2">
            <span
              style={{ color: "#636363", fontSize: "16px", fontWeight: "500" }}
            >
              Subject
            </span>
            <b>Technical Support</b>
          </div>
          <div className="d-flex justify-content-between align-items-center mb-2">
            <span
              style={{ color: "#636363", fontSize: "16px", fontWeight: "500" }}
            >
              Type
            </span>
            <b>Call Issue</b>
          </div>
          <div className="d-flex justify-content-between align-items-center mb-2">
            <span
              style={{ color: "#636363", fontSize: "16px", fontWeight: "500" }}
            >
              Call ID
            </span>
            <b>123456</b>
          </div>
          <div className="d-flex justify-content-between align-items-center mb-2">
            <span
              style={{ color: "#636363", fontSize: "16px", fontWeight: "500" }}
            >
              Created by
            </span>
            <b>Amar Mohamed</b>
          </div>
          <div className="d-flex justify-content-between align-items-center mb-2">
            <span
              style={{ color: "#636363", fontSize: "16px", fontWeight: "500" }}
            >
              Created at
            </span>
            <b>2023-07-10 13:45</b>
          </div>
          <div className="hr"></div>
          <div className="chat-box">
            <img className="w-100" src={chat} alt="chat" />
            {status ? (
              <div className="chat-input">
                <input type="text" placeholder="Type Your Message" />
                <button>Send</button>
                <button
                  onClick={() => setModalShow(true)}
                  className="bg-danger"
                >
                  Close Ticket
                </button>
                <ClosedTicketModal
                  show={modalShow}
                  onHide={() => setModalShow(false)}
                />
              </div>
            ) : null}
          </div>
        </div>
      </Container>

      <div>
        <p className="mb-3 mt-5 mx-4 text-secondary fw-bold">
          Powered by SmartServe
        </p>
      </div>
    </>
  );
};

export default TicketDetails;
