
// Example user data
export const calls = [
  {
    callID: 23456,
    callerName: "John Doe",
    callerNumber: "+1 234 567 890",
    callDate: "2023-07-10 14:05",
    callDuration: "5 Mins",
    callType: "Inquiry",
    status: "Completed",
    by: "AI",
  },
  {
    callID: 23457,
    callerName: "Jane Smith",
    callerNumber: "+1 234 567 891",
    callDate: "2023-07-11 15:10",
    callDuration: "10 Mins",
    callType: "Complaint",
    status: "Canceled",
    by: "Agent",
  },
  {
    callID: 23458,
    callerName: "Alice Johnson",
    callerNumber: "+1 234 567 892",
    callDate: "2023-07-12 16:20",
    callDuration: "7 Mins",
    callType: "Support",
    status: "Completed",
    by: "AI",
  },
];