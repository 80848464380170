import logo from "../../assets/RestDash/mainLogo.svg";
import icon_1 from "../../assets/RestDash/sideMenuIcons/icon_1.png";
import icon_2 from "../../assets/RestDash/sideMenuIcons/icon_2.png";
import icon_3 from "../../assets/RestDash/sideMenuIcons/icon_3.png";
import icon_4 from "../../assets/RestDash/sideMenuIcons/icon_4.png";
import icon_5 from "../../assets/RestDash/sideMenuIcons/icon_5.png";
import icon_6 from "../../assets/RestDash/sideMenuIcons/icon_6.svg";
import icon_7 from "../../assets/RestDash/sideMenuIcons/icon_7.svg";
import icon_8 from "../../assets/RestDash/sideMenuIcons/icon_8.svg";
import black_1 from "../../assets/RestDash/sideMenuIcons/black_1.png";
import black_2 from "../../assets/RestDash/sideMenuIcons/black_2.png";
import black_3 from "../../assets/RestDash/sideMenuIcons/black_3.png";
import black_4 from "../../assets/RestDash/sideMenuIcons/black_4.png";
import black_5 from "../../assets/RestDash/sideMenuIcons/black_5.png";
import black_6 from "../../assets/RestDash/sideMenuIcons/black_6.svg";
import black_7 from "../../assets/RestDash/sideMenuIcons/black_7.svg";
import black_8 from "../../assets/RestDash/sideMenuIcons/black_8.svg";
// import icon_7 from "../../assets/RestDash/sideMenuIcons/logOut.svg";
import { TbLogout2 } from "react-icons/tb";

import "./restDashSideMenu.css";
import { NavLink, useLocation, useNavigate } from "react-router-dom";

const RestDashSideMenu = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  return (
    <div className="sideMenu">
      <img className="w-100 px-1 mb-5" src={logo} alt="logo" />
      <ul className="mt-5">
        <NavLink to="dashBoard">
          <img src={pathname === "/dashBoard" ? black_1 : icon_1} alt="icon" />
        </NavLink>
        <NavLink to="restaurantLists">
          <img
            src={pathname === "/restaurantLists" ? black_2 : icon_2}
            alt="icon"
          />
        </NavLink>
        <NavLink to="sttSubscribers">
          <img
            src={pathname === "/sttSubscribers" ? black_3 : icon_3}
            alt="icon"
          />
        </NavLink>
        <NavLink to="real-time-agents">
          <img
            src={pathname === "/real-time-agents" ? black_7 : icon_7}
            alt="icon"
          />
        </NavLink>
        <NavLink to="voice-bot-home">
          <img
            src={pathname === "/voice-bot-home" ? black_8 : icon_8}
            alt="icon"
          />
        </NavLink>
        <NavLink to="requests">
          <img src={pathname === "/requests" ? black_4 : icon_4} alt="icon" />
        </NavLink>
        <NavLink to="tickets">
          <img src={pathname === "/tickets" ? black_6 : icon_6} alt="icon" />
        </NavLink>
        <NavLink to="settings">
          <img src={pathname === "/settings" ? black_5 : icon_5} alt="icon" />
        </NavLink>
        <div
          style={{ marginBlock: "50px" }}
          onClick={() => {
            localStorage.clear();
            navigate("/login");
          }}
        >
          <TbLogout2
            style={{ width: "25px", height: "25px", cursor: "pointer" }}
          />

          {/* <img
            src={icon_7}
            alt="icon"
            style={{ transform: "rotate(180deg)" }}
          /> */}
        </div>
      </ul>
    </div>
  );
};

export default RestDashSideMenu;
