import React, { useEffect, useState } from "react";
import DashBoardFooter from "../DashBoardFooter/DashBoardFooter";
import RestDashNav from "../RestDashNav/RestDashNav";
import { Col, Row } from "react-bootstrap";
import { getJoinRequest } from "../../utilies/api";
import LoaderSpinner from "../LoaderSpinner";
import PaginationComponent from "../PaginationComponent/PaginationComponent";
import { MdPersonAddAlt1 } from "react-icons/md";
import AddRestaurant from "../AddRestaurant";

//   get date
export function formatDate1(dateString) {
  const options = { day: "numeric", month: "short", year: "numeric" };
  const date = new Date(dateString);
  return date.toLocaleDateString("en-GB", options);
}
const Requests = () => {
  const [show, setShow] = useState(false);
  const [showId, setShowId] = useState(0);
  const handleClose = () => setShow(false);
  const handleShow = (idx) => {
    setShow(true);
    setShowId(idx);
  };
  const [isLoading, setIsLoading] = useState(true);
  const [requests, setRequests] = useState([]);
  const [restName, setRestName] = useState("");
  const [restBranches, setRestBranches] = useState("");
  const [requestDate, setRequestDate] = useState("");
  const handleDateChange = (event) => {
    const date = event.target.value;
    const formattedDate = formatDate(date);
    setRequestDate(formattedDate);
  };

  const formatDate = (date) => {
    const [year, month, day] = date.split("-");
    return `${month}/${day}/${year}`;
  };
  const getRequests = async (restName, restBranches, requestDate) => {
    try {
      const { data } = await getJoinRequest(
        restName,
        restBranches,
        requestDate
      );
      setRequests(data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getRequests(restName, restBranches, requestDate);
  }, [restName, restBranches, requestDate]);

  return (
    <>
      <RestDashNav
        title={"Requests"}
        subTitle={`${requests.length ? requests.length : 0} Total Requests`}
      />
      <div className="my-orders">
        <h1>Search & Filter</h1>
        <Row>
          <Col xs={12} md={4} lg={3}>
            <input
              type="text"
              className="form-control"
              placeholder="Restaurant Name"
              value={restName}
              onChange={(e) => setRestName(e.target.value)}
            />
          </Col>
          <Col xs={12} md={4} lg={3}>
            <input
              type="number"
              min="0"
              className="form-control"
              placeholder="Number Of Branches"
              value={restBranches}
              onChange={(e) => setRestBranches(e.target.value)}
            />
          </Col>
          {/* <Col xs={12} md={4} lg={3}>
            <select className="form-select" defaultValue="Status">
              <option disabled>Status</option>
              <option value={true}>Approved</option>
              <option value={false}>In Active</option>
            </select>
          </Col> */}
          <Col xs={12} md={4} lg={3}>
            <input
              type="date"
              className="form-control"
              onChange={handleDateChange}
            />
          </Col>
        </Row>
        {isLoading ? (
          <LoaderSpinner />
        ) : (
          <>
            <div
              className="table-container table-responsive mt-3 text-nowrap text-capitalize"
              style={{ height: "70vh" }}
            >
              <table className="table table-borderless">
                <thead>
                  <tr>
                    <th scope="col">Request Date</th>
                    <th scope="col">First Name</th>
                    <th scope="col">Last Name</th>
                    <th scope="col">Email</th>
                    <th scope="col">Phone Number</th>
                    <th scope="col">Restaurant Name</th>
                    <th scope="col">Address</th>
                    <th scope="col">Job Title</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {requests.length > 0 ? (
                    requests.map((request, idx) => (
                      <tr key={request.id}>
                        <td>
                          <p>{formatDate1(request?.createdAt)}</p>
                        </td>
                        <td>
                          <p>{request?.firstName}</p>
                        </td>
                        <td>
                          <p>{request?.lastName}</p>
                        </td>
                        <td>
                          <p>{request?.email}</p>
                        </td>
                        <td>
                          <p>{request?.phone}</p>
                        </td>
                        <td>
                          <p>{request?.restaurantName}</p>
                        </td>
                        <td>
                          <p>{request?.address}</p>
                        </td>
                        <td>
                          <p>{request?.jobTitle}</p>
                        </td>
                        <td className="text-center">
                          <MdPersonAddAlt1
                            onClick={() => handleShow(idx)}
                            className="action-icons"
                          />
                          <AddRestaurant
                            requests={requests}
                            idx={idx}
                            show={showId === idx && show}
                            handleClose={handleClose}
                          />
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td
                        style={{ fontWeight: "bold" }}
                        className="text-danger"
                        colSpan={6}
                      >
                        No Restaurants Requests Found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <PaginationComponent />
          </>
        )}
        <DashBoardFooter title={"Powered by SmartServe"} />
      </div>
    </>
  );
};

export default Requests;
