import React, { useEffect, useState } from "react";
import RestDashNav from "../RestDashNav/RestDashNav";
import { ToastContainer } from "react-toastify";
import { Col, Row } from "react-bootstrap";
import DashBoardFooter from "../DashBoardFooter/DashBoardFooter";
import { notifyError, notifySuccess } from "../toasters";
import { adminUpdateRestaurant, getAllRestaurants } from "../../utilies/api";
import { useNavigate, useParams } from "react-router-dom";
import LoaderSpinner from "../LoaderSpinner";

const EditRestaurantList = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const token = localStorage.getItem("admin_token");
  const [profile, setProfile] = useState({
    status: "",
    name: "",
    phone: "",
    address: "",
    email: "",
    password: "",
  });
  const handleChange = (event) => {
    const { target } = event;
    setProfile((prevState) => ({
      ...prevState,
      [target.name]: target.value,
    }));
  };

  const handleChangePhone = (event) => {
    const newValue = event.target.value;

    // Remove any non-numeric characters
    const numericValue = newValue.replace(/\D/g, "");

    setProfile((prevState) => ({
      ...prevState,
      phone: numericValue,
    }));
  };
  // update profile
  const handleSubmitAndRedirect = async (e) => {
    e.preventDefault();
    const formData = {
      id: +id,
      status: profile.status,
      name: profile.name,
      phone: profile.phone,
      address: profile.address,
    };
    try {
      if (
        profile.name !== "" &&
        profile.phone !== "" &&
        profile.address !== ""
      ) {
        const response = await adminUpdateRestaurant(formData);
        notifySuccess(response.data.message);
        setTimeout(() => {
          navigate("/restaurantLists");
        }, 1500);
      } else {
        notifyError("Please Fill All The Fields");
      }
    } catch (error) {
      notifyError(error.response.data.message);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const { data } = await getAllRestaurants(id, "", "", "");
        setProfile({
          status: data[0].status,
          name: data[0].name,
          address: data[0].address,
          email: data[0].email,
          phone: data[0].phone,
        });
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        notifyError(error.response.data.message);
      }
    })();
  }, [token, id]);
  return (
    <>
      <RestDashNav title={`Edit ${profile.name} Restaurant`} />
      <div className="my-orders">
        <ToastContainer />
        <div className="table-container">
          {isLoading ? (
            <LoaderSpinner />
          ) : (
            <Row className="mb-4">
              <div className="d-flex align-items-center justify-content-between my-3">
                <h1>Are you want Restaurant Active?</h1>
                <div className="rest-status">
                  <div className="form-switch">
                    <input
                      type="checkbox"
                      id="custom-switch"
                      className="form-check-input"
                      name="status"
                      checked={profile.status}
                      onChange={(e) => {
                        setProfile((prevState) => ({
                          ...prevState,
                          status: e.target.checked,
                        }));
                      }}
                    />
                  </div>
                </div>
              </div>
              <Col xs={12} md={6} lg={6} className="mb-4">
                <label
                  style={{
                    marginBottom: "15px",
                    fontSize: "18px",
                    fontWeight: "bold",
                  }}
                  htmlFor="name"
                >
                  Restaurant Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Restaurant name"
                  id="name"
                  name="name"
                  value={profile.name}
                  onChange={handleChange}
                />
              </Col>
              <Col xs={12} md={6} lg={6} className="mb-4">
                <label
                  style={{
                    marginBottom: "15px",
                    fontSize: "18px",
                    fontWeight: "bold",
                  }}
                  htmlFor="phone"
                >
                  Restaurant phone number
                </label>
                <input
                  type="tel"
                  className="form-control"
                  placeholder="Enter phone number"
                  id="phone"
                  onChange={handleChangePhone}
                  name="phone"
                  value={profile.phone}
                />
              </Col>
              <Col xs={12} md={12} lg={12} className="mb-4">
                <label
                  style={{
                    marginBottom: "15px",
                    fontSize: "18px",
                    fontWeight: "bold",
                  }}
                  htmlFor="address"
                >
                  Address In details
                </label>
                <input
                  type="text"
                  className="form-control resize-none"
                  id="address"
                  placeholder="Address In details"
                  onChange={handleChange}
                  name="address"
                  value={profile.address}
                />
              </Col>
              <Col xs={12} md={12} lg={12}>
                <label
                  style={{
                    marginBottom: "25px",
                    fontSize: "18px",
                    fontWeight: "bold",
                  }}
                  htmlFor="Account Creation"
                >
                  Account Creation
                </label>
              </Col>
              <Col xs={12} md={12} lg={6} className="mb-3">
                <label
                  style={{
                    marginBottom: "15px",
                    fontSize: "18px",
                    fontWeight: "bold",
                  }}
                  htmlFor="email"
                >
                  Email Address
                </label>
                <input
                  readOnly
                  type="email"
                  className="form-control"
                  id="email"
                  placeholder="Email Address "
                  name="email"
                  value={profile.email}
                />
              </Col>
              <Col xs={12} md={12} lg={6} className="mb-4">
                <label
                  style={{
                    marginBottom: "15px",
                    fontSize: "18px",
                    fontWeight: "bold",
                  }}
                  htmlFor="password"
                >
                  Password
                </label>
                <input
                  readOnly
                  type="password"
                  className="form-control"
                  id="password"
                  placeholder="********************"
                  name="password"
                  value="********************"
                />
              </Col>
            </Row>
          )}

          <div className="d-flex justify-content-end">
            <button onClick={handleSubmitAndRedirect} className="add-item-btn">
              Save
            </button>
          </div>
        </div>
        <DashBoardFooter />
      </div>
    </>
  );
};

export default EditRestaurantList;
