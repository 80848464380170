// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sd-container {
  position: relative;
  float: left;
  width: 100%;
  height: 50px;
}

.sd {
  padding: 5px 10px;
  height: 100%;
  width: 100%;
  border-radius: 10px;
  color: "#636363";
  border: 1px solid rgba(0, 0, 0, 0.5);
}

.open-button {
  position: absolute;
  top: 10px;
  right: 11px;
  width: 25px;
  height: 25px;
  background: #fff;
  pointer-events: none;
}

.open-button button {
  border: none;
  background: transparent;
}
`, "",{"version":3,"sources":["webpack://./src/Pages/Tickets/tickets.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,WAAW;EACX,WAAW;EACX,YAAY;AACd;;AAEA;EACE,iBAAiB;EACjB,YAAY;EACZ,WAAW;EACX,mBAAmB;EACnB,gBAAgB;EAChB,oCAAoC;AACtC;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,WAAW;EACX,WAAW;EACX,YAAY;EACZ,gBAAgB;EAChB,oBAAoB;AACtB;;AAEA;EACE,YAAY;EACZ,uBAAuB;AACzB","sourcesContent":[".sd-container {\n  position: relative;\n  float: left;\n  width: 100%;\n  height: 50px;\n}\n\n.sd {\n  padding: 5px 10px;\n  height: 100%;\n  width: 100%;\n  border-radius: 10px;\n  color: \"#636363\";\n  border: 1px solid rgba(0, 0, 0, 0.5);\n}\n\n.open-button {\n  position: absolute;\n  top: 10px;\n  right: 11px;\n  width: 25px;\n  height: 25px;\n  background: #fff;\n  pointer-events: none;\n}\n\n.open-button button {\n  border: none;\n  background: transparent;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
