// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.react-select__multi-value {
  background-color: #415fac !important;
  border-radius: 100px !important;
  color: white !important;
}
.react-select__multi-value .react-select__multi-value__label {
  color: white !important;
  font-size: 16px !important;
  font-weight: 500 !important;
}
`, "",{"version":3,"sources":["webpack://./src/Pages/EditSubscriber/editSubscriber.css"],"names":[],"mappings":"AAAA;EACE,oCAAoC;EACpC,+BAA+B;EAC/B,uBAAuB;AACzB;AACA;EACE,uBAAuB;EACvB,0BAA0B;EAC1B,2BAA2B;AAC7B","sourcesContent":[".react-select__multi-value {\n  background-color: #415fac !important;\n  border-radius: 100px !important;\n  color: white !important;\n}\n.react-select__multi-value .react-select__multi-value__label {\n  color: white !important;\n  font-size: 16px !important;\n  font-weight: 500 !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
