// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.error-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f8d7da;
  color: #721c24;
  text-align: center;
}

.error-page h1 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.error-page p {
  font-size: 1.25rem;
  margin-bottom: 2rem;
}

.error-page button {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  background-color: #721c24;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.error-page button:hover {
  background-color: #501414;
}
`, "",{"version":3,"sources":["webpack://./src/Components/errors/errorHandler.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,aAAa;EACb,yBAAyB;EACzB,cAAc;EACd,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;EACjB,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,oBAAoB;EACpB,eAAe;EACf,yBAAyB;EACzB,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".error-page {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  height: 100vh;\n  background-color: #f8d7da;\n  color: #721c24;\n  text-align: center;\n}\n\n.error-page h1 {\n  font-size: 2.5rem;\n  margin-bottom: 1rem;\n}\n\n.error-page p {\n  font-size: 1.25rem;\n  margin-bottom: 2rem;\n}\n\n.error-page button {\n  padding: 0.5rem 1rem;\n  font-size: 1rem;\n  background-color: #721c24;\n  color: #fff;\n  border: none;\n  border-radius: 5px;\n  cursor: pointer;\n}\n\n.error-page button:hover {\n  background-color: #501414;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
