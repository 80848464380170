import React, { useState } from "react";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import { addRestaurant } from "../utilies/api";

const AddRestaurant = ({ show, handleClose, requests, idx }) => {
  const [userLoading, setUserLodaing] = useState(false);
  const [userSuccMsg, setUserSuccMsg] = useState("");
  const [userErrMsg, setUserErrMsg] = useState("");
  const [userEmail, setUserEmail] = useState(requests[idx].email);
  const [userPassword, setUserPassword] = useState("");
  const addRestaurantFun = async () => {
    try {
      let formData = new FormData();
      formData.append("email", userEmail);
      formData.append("password", userPassword);
      formData.append("restaurantRequestId", requests[idx].id);
      setUserLodaing(true);
      const response = await addRestaurant(formData);
      setUserLodaing(false);
      setUserSuccMsg(response.data.message);
      setTimeout(() => {
        handleClose();
        setUserSuccMsg("");
        setUserPassword("");
        setUserErrMsg("");
      }, 2000);
    } catch (error) {
      setUserLodaing(false);
      setUserErrMsg(error.response.data.message);
      setTimeout(() => {
        setUserErrMsg("");
        setUserSuccMsg("");
        setUserPassword("");
      }, 2000);
    }
  };
  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      show={show}
      onHide={handleClose}
    >
      <Modal.Header closeButton>
        <Modal.Title>Add New Restaurant</Modal.Title>
      </Modal.Header>
      {userErrMsg ? (
        <div className="alert alert-danger m-3 mb-0" role="alert">
          {userErrMsg}
        </div>
      ) : null}
      {userSuccMsg ? (
        <div className="alert alert-success m-3 mb-0" role="alert">
          {userSuccMsg}
        </div>
      ) : null}
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3" controlId="name">
            <Form.Label>Email Address</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter Restaurant Email"
              value={userEmail}
              onChange={(e) => setUserEmail(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="email">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Enter Restaurant Password"
              value={userPassword}
              onChange={(e) => setUserPassword(e.target.value)}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          className="w-25 py-2 text-white fs-5 fw-bold"
          onClick={() => {
            addRestaurantFun();
            setUserLodaing(true);
          }}
        >
          {userLoading ? <Spinner /> : "Save"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddRestaurant;
