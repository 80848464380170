// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.paginate {
  display: flex;
  gap: 20px;
  align-items: center;
}
.number {
  border: 1px solid rgba(226, 226, 226, 1);
  padding: 5px 20px;
  border-radius: 100px;
  font-weight: bold;
  font-size: 14px;
}
.arrows {
  display: flex;
  align-items: center;
  gap: 10px;
}
.arrows img {
  width: 15px;
  height: 15px;
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/Components/PaginationComponent/paginationComponent.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,SAAS;EACT,mBAAmB;AACrB;AACA;EACE,wCAAwC;EACxC,iBAAiB;EACjB,oBAAoB;EACpB,iBAAiB;EACjB,eAAe;AACjB;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAS;AACX;AACA;EACE,WAAW;EACX,YAAY;EACZ,eAAe;AACjB","sourcesContent":[".paginate {\n  display: flex;\n  gap: 20px;\n  align-items: center;\n}\n.number {\n  border: 1px solid rgba(226, 226, 226, 1);\n  padding: 5px 20px;\n  border-radius: 100px;\n  font-weight: bold;\n  font-size: 14px;\n}\n.arrows {\n  display: flex;\n  align-items: center;\n  gap: 10px;\n}\n.arrows img {\n  width: 15px;\n  height: 15px;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
