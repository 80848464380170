// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboard-footer{
    text-transform: capitalize;
    letter-spacing: 1px;
    color: rgba(0, 0, 0, 0.5);
    font-size: 14px;
    font-weight: bold;
    font-family: sans-serif;
}`, "",{"version":3,"sources":["webpack://./src/Components/DashBoardFooter/dashBoardFooter.css"],"names":[],"mappings":"AAAA;IACI,0BAA0B;IAC1B,mBAAmB;IACnB,yBAAyB;IACzB,eAAe;IACf,iBAAiB;IACjB,uBAAuB;AAC3B","sourcesContent":[".dashboard-footer{\n    text-transform: capitalize;\n    letter-spacing: 1px;\n    color: rgba(0, 0, 0, 0.5);\n    font-size: 14px;\n    font-weight: bold;\n    font-family: sans-serif;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
