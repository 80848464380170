import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import Login from "../Pages/Login/Login";
import ForgetPassword from "../Pages/ForgetPassword/ForgetPassword";
import CreatePassword from "../Pages/CreatePassword/CreatePassword";
import RestDashboard from "../LayOut/RestDashboard";
import DashBoard from "../Pages/DashBoard/DashBoard";
import Profile from "../Pages/Profile/Profile";
import AuthLayOut from "../LayOut/AuthLayOut";
import RestaurantLists from "../Components/RestaurantLists/RestaurantLists";
import Requests from "../Components/Requests/Requests";
import SttSubscribers from "../Components/SttSubscribers/SttSubscribers";
import ErrorRouteHandler from "../Components/errors/ErrorHandler";
import AddRestaurantList from "../Components/AddRestaurantList/AddRestaurantList";
import EditRestaurantList from "../Components/EditRestaurantList/EditRestaurantList";
import ProtectedRoute from "../Components/auth/ProtectedRoute";
import PublicRoute from "../Components/auth/PublickRoute";
import Tickets from "../Pages/Tickets/Tickets";
import TicketDetails from "../Pages/TicketDetails/TicketDetails";
import RealTimeAgents from "../Pages/RealTimeAgents/RealTimeAgents";
import AddNewSubscriber from "../Pages/AddNewSubscriber/AddNewSubscriber";
import EditSubscriber from "../Pages/EditSubscriber/EditSubscriber";
import SubscriberDetails from "../Pages/SubscriberDetails/SubscriberDetails";
import VoiceBotHome from "../Pages/VoiceBotHome/VoiceBotHome";
import VoiceAddNewSubscriber from "../Pages/VoiceAddNewSubscriber/VoiceAddNewSubscriber";
import VoiceEditSubscriber from "../Pages/VoiceEditSubscriber/VoiceEditSubscriber";
import VoiceSubscriberDetails from "../Pages/VoiceSubscriberDetails/VoiceSubscriberDetails";

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/" element={<AuthLayOut />}>
        <Route
          index
          element={
            <PublicRoute redirectPath="/dashBoard">
              <Login />
            </PublicRoute>
          }
        />
        <Route
          path="login"
          element={
            <PublicRoute redirectPath="/dashBoard">
              <Login />
            </PublicRoute>
          }
        />
        <Route
          path="forgetPassword"
          element={
            <PublicRoute redirectPath="/dashBoard">
              <ForgetPassword />
            </PublicRoute>
          }
        />
        <Route
          path="agentUpdatePassword"
          element={
            <PublicRoute redirectPath="/dashBoard">
              <CreatePassword />
            </PublicRoute>
          }
        />
      </Route>
      <Route path="/" element={<RestDashboard />}>
        <Route
          path="dashBoard"
          element={
            <ProtectedRoute redirectPath="/login">
              <DashBoard />
            </ProtectedRoute>
          }
          errorElement={<ErrorRouteHandler />}
        />
        <Route
          path="restaurantLists"
          element={
            <ProtectedRoute redirectPath="/login">
              <RestaurantLists />
            </ProtectedRoute>
          }
          errorElement={<ErrorRouteHandler />}
        />
        <Route
          path="addRestaurant"
          element={
            <ProtectedRoute redirectPath="/login">
              <AddRestaurantList />
            </ProtectedRoute>
          }
          errorElement={<ErrorRouteHandler />}
        />
        <Route
          path="editRestaurant/:id"
          element={
            <ProtectedRoute redirectPath="/login">
              <EditRestaurantList />
            </ProtectedRoute>
          }
          errorElement={<ErrorRouteHandler />}
        />
        <Route
          path="sttSubscribers"
          element={
            <ProtectedRoute redirectPath="/login">
              <SttSubscribers />
            </ProtectedRoute>
          }
          errorElement={<ErrorRouteHandler />}
        />
        <Route
          path="Real-time-agents"
          element={
            <ProtectedRoute redirectPath="/login">
              <RealTimeAgents />
            </ProtectedRoute>
          }
          errorElement={<ErrorRouteHandler />}
        />
        <Route
          path="add-new-subscriber"
          element={
            <ProtectedRoute redirectPath="/login">
              <AddNewSubscriber />
            </ProtectedRoute>
          }
          errorElement={<ErrorRouteHandler />}
        />
        <Route
          path="edit-subscriber"
          element={
            <ProtectedRoute redirectPath="/login">
              <EditSubscriber />
            </ProtectedRoute>
          }
          errorElement={<ErrorRouteHandler />}
        />
        <Route
          path="subscriber-details"
          element={
            <ProtectedRoute redirectPath="/login">
              <SubscriberDetails />
            </ProtectedRoute>
          }
          errorElement={<ErrorRouteHandler />}
        />
        <Route
          path="voice-bot-home"
          element={
            <ProtectedRoute redirectPath="/login">
              <VoiceBotHome />
            </ProtectedRoute>
          }
          errorElement={<ErrorRouteHandler />}
        />
        <Route
          path="voice-add-new-subscriber"
          element={
            <ProtectedRoute redirectPath="/login">
              <VoiceAddNewSubscriber />
            </ProtectedRoute>
          }
          errorElement={<ErrorRouteHandler />}
        />
        <Route
          path="voice-edit-subscriber"
          element={
            <ProtectedRoute redirectPath="/login">
              <VoiceEditSubscriber />
            </ProtectedRoute>
          }
          errorElement={<ErrorRouteHandler />}
        />
        <Route
          path="voice-subscriber-details"
          element={
            <ProtectedRoute redirectPath="/login">
              <VoiceSubscriberDetails />
            </ProtectedRoute>
          }
          errorElement={<ErrorRouteHandler />}
        />
        <Route
          path="requests"
          element={
            <ProtectedRoute redirectPath="/login">
              <Requests />
            </ProtectedRoute>
          }
          errorElement={<ErrorRouteHandler />}
        />
        <Route
          path="tickets"
          element={
            <ProtectedRoute redirectPath="/login">
              <Tickets />
            </ProtectedRoute>
          }
          errorElement={<ErrorRouteHandler />}
        />
        <Route
          path="ticketDetails"
          element={
            <ProtectedRoute redirectPath="/login">
              <TicketDetails />
            </ProtectedRoute>
          }
          errorElement={<ErrorRouteHandler />}
        />
        <Route
          path="settings"
          element={
            <ProtectedRoute redirectPath="/login">
              <Profile />
            </ProtectedRoute>
          }
          errorElement={<ErrorRouteHandler />}
        />
      </Route>
    </>
  )
);

export default router;
