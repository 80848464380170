import { jwtDecode } from "jwt-decode";
import { Navigate, useLocation } from "react-router-dom";

const ProtectedRoute = ({ redirectPath, children }) => {
  const retToken = localStorage.getItem("admin_token");
  const location = useLocation();

  if (!retToken) {
    return (
      <Navigate to={redirectPath} state={{ path: location.pathname }} replace />
    );
  }

  try {
    // Decode the token to get the expiration time
    const { exp } = jwtDecode(retToken);

    // Check if the token is expired
    if (Date.now() >= exp * 1000) {
      // Token is expired, clear it from storage and redirect to login
      localStorage.removeItem("admin_token");
      return (
        <Navigate
          to={redirectPath}
          state={{ path: location.pathname }}
          replace
        />
      );
    }
  } catch (e) {
    // If there's an error decoding the token, redirect to login
    localStorage.removeItem("admin_token");
    return (
      <Navigate to={redirectPath} state={{ path: location.pathname }} replace />
    );
  }

  return children;
};

export default ProtectedRoute;
