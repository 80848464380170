// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.clear-link {
  color: #ff3434 !important;
  cursor: pointer;
}
.form-select,
.form-control {
  border: 1px solid #636363 !important;
}
`, "",{"version":3,"sources":["webpack://./src/Components/SearchFilter/searchFilter.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,eAAe;AACjB;AACA;;EAEE,oCAAoC;AACtC","sourcesContent":[".clear-link {\n  color: #ff3434 !important;\n  cursor: pointer;\n}\n.form-select,\n.form-control {\n  border: 1px solid #636363 !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
