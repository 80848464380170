import { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import { jwtDecode } from "jwt-decode";
import { Container } from "react-bootstrap";
import mainLogo from "../../assets/RestDash/mainLogo.svg";
import { Link, useNavigate } from "react-router-dom";
import DashBoardFooter from "../../Components/DashBoardFooter/DashBoardFooter";
import { notifyError, notifySuccess } from "../../Components/toasters";
import { adminUpdatePassword } from "../../utilies/api";
import { FaEye, FaEyeSlash } from "react-icons/fa"; // Import icons for showing/hiding password

const CreatePassword = () => {
  const navigate = useNavigate();
  const [UserObject, setUserObject] = useState({});
  const [password, setPassword] = useState("");
  const [cPassword, setCPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility
  const [showCPassword, setShowCPassword] = useState(false); // State to toggle confirm password visibility

  const togglePasswordVisibility = () => setShowPassword(!showPassword);
  const toggleCPasswordVisibility = () => setShowCPassword(!showCPassword);

  const handleUpdatePass = async (e) => {
    e.preventDefault();
    try {
      if (password !== cPassword || password === "") {
        notifyError("Password and confirm password do not match");
        return;
      } else {
        const response = await adminUpdatePassword({
          id: UserObject.id,
          password,
        });
        notifySuccess(response.data.message);
        setPassword("");
        setCPassword("");
        setTimeout(() => {
          navigate("/login");
        }, 2500);
      }
    } catch (error) {
      notifyError(error.response.data.message);
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const accessToken = params.get("logger");
    if (accessToken) {
      const { record } = jwtDecode(accessToken);
      setUserObject(record);
    }
  }, []);

  return (
    <div className="login">
      <ToastContainer />
      <Container>
        <img className="login-logo mb-3" src={mainLogo} alt="logo" />
        <ToastContainer />
        <div className="side-form">
          <div className="side-form-section">
            <h1 className="mb-3">Create New Password</h1>
            <p className="mb-4">
              You've successfully verified your identity. Please enter a new
              password <br /> below to secure your account.
            </p>
            <form className="form" onSubmit={handleUpdatePass}>
              <label className="mb-2" htmlFor="password">
                New Password
              </label>
              <div
                className="password-wrapper mb-3"
                style={{ position: "relative" }}
              >
                <input
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  autoComplete="false"
                  required
                  className="form-input"
                  type={showPassword ? "text" : "password"}
                  name="password"
                  id="password"
                  placeholder="Enter your new password"
                />
                <span
                  style={{
                    position: "absolute",
                    right: "15px",
                    top: "50%",
                    transform: "translateY(-50%)",
                    color: "#415FAC",
                    cursor: "pointer",
                  }}
                  className="password-toggle-icon"
                  onClick={togglePasswordVisibility}
                >
                  {showPassword ? (
                    <FaEyeSlash style={{ fontSize: "20px" }} />
                  ) : (
                    <FaEye style={{ fontSize: "20px" }} />
                  )}
                </span>
              </div>
              <label className="mb-2" htmlFor="cpassword">
                Confirm New Password
              </label>
              <div
                className="password-wrapper mb-5"
                style={{ position: "relative" }}
              >
                <input
                  value={cPassword}
                  onChange={(e) => setCPassword(e.target.value)}
                  autoComplete="false"
                  required
                  className="form-input"
                  type={showCPassword ? "text" : "password"}
                  name="cpassword"
                  id="cpassword"
                  placeholder="Re-enter your new password"
                />
                <span
                  style={{
                    position: "absolute",
                    right: "15px",
                    top: "50%",
                    transform: "translateY(-50%)",
                    color: "#415FAC",
                    cursor: "pointer",
                  }}
                  className="password-toggle-icon"
                  onClick={toggleCPasswordVisibility}
                >
                  {showCPassword ? (
                    <FaEyeSlash style={{ fontSize: "20px" }} />
                  ) : (
                    <FaEye style={{ fontSize: "20px" }} />
                  )}
                </span>
              </div>
              <input className="login-button mb-3" type="submit" value="Send" />
              <div className="forget-link text-center">
                <Link to="/login" className="forget">
                  Back to login
                </Link>
              </div>
            </form>
          </div>
        </div>
        <div className="text-center mt-3">
          <DashBoardFooter title={"© 2024 SmartServe"} />
        </div>
      </Container>
    </div>
  );
};

export default CreatePassword;
