import React, { useEffect, useState } from "react";
import DashBoardFooter from "../DashBoardFooter/DashBoardFooter";
import RestDashNav from "../RestDashNav/RestDashNav";
import { Col, Row } from "react-bootstrap";
import PaginationComponent from "../PaginationComponent/PaginationComponent";
import { getAllCompanies } from "../../utilies/api";
import LoaderSpinner from "../LoaderSpinner";
import { formatDate1 } from "../Requests/Requests";

const SttSubscribers = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [allCompanies, setAllCompanies] = useState([]);
  const [id, setId] = useState();
  const [sttName, setSttName] = useState("");
  const [sttMinutes, setSttMinutes] = useState();
  const getAllCompaniesFun = async (id, name, numOfMinutes) => {
    try {
      const { data } = await getAllCompanies(id, name, numOfMinutes);
      setAllCompanies(data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getAllCompaniesFun(id, sttName, sttMinutes);
  }, [id, sttName, sttMinutes]);
  return (
    <>
      <RestDashNav
        title={"Speech to text Subscribers"}
        subTitle={`${
          allCompanies.length ? allCompanies.length : 0
        } Total Subscribers`}
      />
      <div className="my-orders">
        <h1>Search & Filter</h1>
        <Row>
          <Col xs={12} md={4} lg={3}>
            <input
              type="text"
              className="form-control"
              placeholder="ID"
              value={id}
              onChange={(e) => setId(e.target.value)}
            />
          </Col>
          <Col xs={12} md={4} lg={3}>
            <input
              type="text"
              className="form-control"
              placeholder="Name"
              value={sttName}
              onChange={(e) => setSttName(e.target.value)}
            />
          </Col>
          <Col xs={12} md={4} lg={3}>
            <input
              type="number"
              min="1"
              className="form-control"
              placeholder="Number Of Minutes"
              value={sttMinutes}
              onChange={(e) => setSttMinutes(e.target.value)}
            />
          </Col>
        </Row>
        {isLoading ? (
          <LoaderSpinner />
        ) : (
          <div
            className="table-container table-responsive text-nowrap text-capitalize"
            style={{ height: "70vh" }}
          >
            <table className="table table-borderless">
              <thead>
                <tr>
                  <th scope="col">Subscriber ID</th>
                  <th scope="col">Agent Details</th>
                  <th scope="col">Subscription Amount</th>
                  <th scope="col">Subscription Date </th>
                  <th scope="col">Expiry Date</th>
                  <th scope="col">Total transcriptions Duration</th>
                </tr>
              </thead>
              {allCompanies.length > 0 ? (
                allCompanies.map((company) => (
                  <tbody key={company?.Subscription?.id}>
                    <tr>
                      <td>#{company?.Subscription?.id}</td>
                      <td>
                        <div className="rest-name">
                          <p>{company?.name}</p>
                          <p>{company?.phone}</p>
                        </div>
                      </td>
                      <td>
                        <span className="total-amount">
                          {company?.Subscription?.Billing || 0}
                        </span>
                      </td>
                      <td>
                        <p>{formatDate1(company?.Subscription?.startDate)}</p>
                      </td>
                      <td>
                        <span
                          style={{ color: "#FF3434" }}
                          className="total-amount"
                        >
                          {formatDate1(company?.Subscription?.endDate)}
                        </span>
                      </td>
                      <td>
                        <p>{company?.Subscription?.numberOfMinutes} Minutes</p>
                      </td>
                    </tr>
                  </tbody>
                ))
              ) : (
                <tbody>
                  <tr>
                    <td
                      style={{ fontWeight: "bold" }}
                      className="text-danger"
                      colSpan={6}
                    >
                      No Stt Subscribers Found
                    </td>
                  </tr>
                </tbody>
              )}
            </table>
          </div>
        )}
        <PaginationComponent />
        <DashBoardFooter title={"Powered by SmartServe"} />
      </div>
    </>
  );
};

export default SttSubscribers;
