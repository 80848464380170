import { Col, Row } from "react-bootstrap";
import person from "../../assets/RestDash/navbar/person.png";
import "./restDashNavbar.css";
import { useEffect, useState } from "react";
import { jwtDecode } from "jwt-decode";
import left_arrow from "../../assets/RestDash/navbar/left_arrow.png";
import { useNavigate } from "react-router-dom";

const RestDashNav = ({ title, subTitle, redirect_path }) => {
  const navigate = useNavigate();
  const token = localStorage.getItem("admin_token");
  const [userData, setUserData] = useState({});

  useEffect(() => {
    if (token) {
      setUserData(jwtDecode(token)?.agent);
    }
  }, [token]);
  return (
    <div className="nav-section">
      <Row className="w-100 m-auto">
        <Col xs={12} md={8} lg={7}>
          <div className="nav-heading">
            {redirect_path ? (
              <img
                onClick={() => navigate(redirect_path)}
                src={left_arrow}
                alt="arrow"
              />
            ) : null}

            <div className="arrow-title mb-3">
              <h2>{title}</h2>
              <p>{subTitle}</p>
            </div>
          </div>
        </Col>
        <Col xs={12} md={4} lg={5}>
          <div className="nav-group">
            <div className="nav-dropdown mb-3">
              <img src={person} alt="person" />
              <span className="nav-title">Welcome {userData?.name}</span>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default RestDashNav;
