import React from "react";
import "./tickets.css";
import eye_icon from "../../assets/RestDash/tickets/eye.svg";
import return_icon from "../../assets/RestDash/tickets/return.svg";
import SearchFilter from "../../Components/SearchFilter/SearchFilter";
import { Table, Container } from "react-bootstrap";
import PaginationComponent from "../../Components/PaginationComponent/PaginationComponent";
import { useNavigate } from "react-router-dom";
import RestDashNav from "../../Components/RestDashNav/RestDashNav";
import DashBoardFooter from "../../Components/DashBoardFooter/DashBoardFooter";
const Tickets = () => {
  const navigate = useNavigate();
  return (
    <>
      <RestDashNav title={"Tickets"} subTitle={"Manage your Tickets"} />
      <Container>
        <SearchFilter
          filterConfig={[
            {
              name: "TicketID",
              label: "Ticket ID",
              type: "text",
              placeholder: "Ticket ID",
            },
            {
              name: "TicketType",
              label: "Ticket Type",
              type: "select",
              options: [
                { value: "", label: "Ticket Type" },
                { value: "Technical Support	", label: "Technical Support" },
                { value: "Call Issue", label: "Call Issue" },
                { value: "Billing Issue", label: "Billing Issue" },
              ],
            },
            {
              name: "CreatedAt",
              label: "Ticket Date & Time",
              type: "datetime-local",
            },

            {
              name: "status",
              type: "select",
              options: [
                { value: "", label: "Status" },
                { value: "Open", label: "Open" },
                { value: "Closed", label: "Closed" },
                { value: "In Progress", label: "In Progress" },
              ],
            },
            {
              name: "Createdby",
              type: "select",
              options: [
                { value: "", label: "Created by" },
                { value: "John Doe", label: "John Doe" },
                { value: "John Smith", label: "John Smith" },
              ],
            },
          ]}
          // onFilterChange={handleFilterChange}
          // onClearFilters={handleClearFilters}
        />
        <hr />
        <Table
          responsive
          borderless
          style={{ textWrap: "nowrap", fontWeight: "bold" }}
        >
          <thead>
            <tr>
              <th style={{ paddingBottom: "25px" }}>Ticket ID</th>
              <th style={{ paddingBottom: "25px" }}>Subject</th>
              <th style={{ paddingBottom: "25px" }}>Type</th>
              <th style={{ paddingBottom: "25px" }}>Created By</th>
              <th style={{ paddingBottom: "25px" }}>Date Created</th>
              <th style={{ paddingBottom: "25px" }}>Status</th>
              <th style={{ paddingBottom: "25px" }}>Last Updated</th>
              <th style={{ paddingBottom: "25px" }}>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td
                style={{
                  color: "#415FAC",
                  fontSize: "16px",
                  cursor: "pointer",
                }}
              >
                123456
              </td>
              <td>Technical Support</td>
              <td>Technical Support</td>
              <td>John Doe</td>
              <td>2023-07-10 14:05</td>
              <td>
                <span
                  style={{
                    backgroundColor: "#F4A223",
                    color: "white",
                    height: "25px",
                    width: "95px",
                    display: "inline-block",
                    textAlign: "center",
                    borderRadius: "100px",
                    fontSize: "14px",
                  }}
                >
                  In Progress
                </span>
              </td>
              <td>2023-07-10 11:00</td>
              <td>
                <div className="d-flex align-items-center gap-2 justify-content-flex-start h-100">
                  <img
                    style={{ cursor: "pointer" }}
                    src={eye_icon}
                    alt="icon"
                    onClick={() => navigate("/ticketDetails")}
                  />
                  <img
                    onClick={() => navigate("/ticketDetails")}
                    style={{ cursor: "pointer" }}
                    src={return_icon}
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  color: "#415FAC",
                  fontSize: "16px",
                  cursor: "pointer",
                }}
              >
                123456
              </td>
              <td>Call Issue</td>
              <td>Call Issue</td>
              <td>John Doe</td>
              <td>2023-07-10 14:05</td>
              <td>
                <span
                  style={{
                    backgroundColor: "#129D54",
                    color: "white",
                    height: "25px",
                    width: "95px",
                    display: "inline-block",
                    textAlign: "center",
                    borderRadius: "100px",
                    fontSize: "14px",
                  }}
                >
                  Open
                </span>
              </td>
              <td>2023-07-10 11:00</td>
              <td>
                <div className="d-flex align-items-center gap-2 justify-content-flex-start h-100">
                  <img
                    style={{ cursor: "pointer" }}
                    src={eye_icon}
                    alt="icon"
                  />
                  <img
                    alt="icon"
                    style={{ cursor: "pointer" }}
                    src={return_icon}
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  color: "#415FAC",
                  fontSize: "16px",
                  cursor: "pointer",
                }}
              >
                123456
              </td>
              <td>Billing Issue</td>
              <td>Billing Issue</td>
              <td>John Doe</td>
              <td>2023-07-10 14:05</td>
              <td>
                <span
                  style={{
                    backgroundColor: "#FF3434",
                    color: "white",
                    height: "25px",
                    width: "95px",
                    display: "inline-block",
                    textAlign: "center",
                    borderRadius: "100px",
                    fontSize: "14px",
                  }}
                >
                  Closed
                </span>
              </td>
              <td>2023-07-10 11:00</td>
              <td>
                <div className="d-flex align-items-center gap-2 justify-content-flex-start h-100">
                  <img
                    style={{ cursor: "pointer" }}
                    src={eye_icon}
                    alt="icon"
                  />
                  <img
                    alt="icon"
                    style={{ cursor: "pointer" }}
                    src={return_icon}
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  color: "#415FAC",
                  fontSize: "16px",
                  cursor: "pointer",
                }}
              >
                123456
              </td>
              <td>Technical Support</td>
              <td>Technical Support</td>
              <td>John Doe</td>
              <td>2023-07-10 14:05</td>
              <td>
                <span
                  style={{
                    backgroundColor: "#F4A223",
                    color: "white",
                    height: "25px",
                    width: "95px",
                    display: "inline-block",
                    textAlign: "center",
                    borderRadius: "100px",
                    fontSize: "14px",
                  }}
                >
                  In Progress
                </span>
              </td>
              <td>2023-07-10 11:00</td>
              <td>
                <div className="d-flex align-items-center gap-2 justify-content-flex-start h-100">
                  <img
                    style={{ cursor: "pointer" }}
                    src={eye_icon}
                    alt="icon"
                  />
                  <img
                    alt="icon"
                    style={{ cursor: "pointer" }}
                    src={return_icon}
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  color: "#415FAC",
                  fontSize: "16px",
                  cursor: "pointer",
                }}
              >
                123456
              </td>
              <td>Call Issue</td>
              <td>Call Issue</td>
              <td>John Doe</td>
              <td>2023-07-10 14:05</td>
              <td>
                <span
                  style={{
                    backgroundColor: "#129D54",
                    color: "white",
                    height: "25px",
                    width: "95px",
                    display: "inline-block",
                    textAlign: "center",
                    borderRadius: "100px",
                    fontSize: "14px",
                  }}
                >
                  Open
                </span>
              </td>
              <td>2023-07-10 11:00</td>
              <td>
                <div className="d-flex align-items-center gap-2 justify-content-flex-start h-100">
                  <img
                    style={{ cursor: "pointer" }}
                    src={eye_icon}
                    alt="icon"
                  />
                  <img
                    alt="icon"
                    style={{ cursor: "pointer" }}
                    src={return_icon}
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  color: "#415FAC",
                  fontSize: "16px",
                  cursor: "pointer",
                }}
              >
                123456
              </td>
              <td>Billing Issue</td>
              <td>Billing Issue</td>
              <td>John Doe</td>
              <td>2023-07-10 14:05</td>
              <td>
                <span
                  style={{
                    backgroundColor: "#FF3434",
                    color: "white",
                    height: "25px",
                    width: "95px",
                    display: "inline-block",
                    textAlign: "center",
                    borderRadius: "100px",
                    fontSize: "14px",
                  }}
                >
                  Closed
                </span>
              </td>
              <td>2023-07-10 11:00</td>
              <td>
                <div className="d-flex align-items-center gap-2 justify-content-flex-start h-100">
                  <img
                    style={{ cursor: "pointer" }}
                    src={eye_icon}
                    alt="icon"
                  />
                  <img
                    alt="icon"
                    style={{ cursor: "pointer" }}
                    src={return_icon}
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  color: "#415FAC",
                  fontSize: "16px",
                  cursor: "pointer",
                }}
              >
                123456
              </td>
              <td>Technical Support</td>
              <td>Technical Support</td>
              <td>John Doe</td>
              <td>2023-07-10 14:05</td>
              <td>
                <span
                  style={{
                    backgroundColor: "#F4A223",
                    color: "white",
                    height: "25px",
                    width: "95px",
                    display: "inline-block",
                    textAlign: "center",
                    borderRadius: "100px",
                    fontSize: "14px",
                  }}
                >
                  In Progress
                </span>
              </td>
              <td>2023-07-10 11:00</td>
              <td>
                <div className="d-flex align-items-center gap-2 justify-content-flex-start h-100">
                  <img
                    style={{ cursor: "pointer" }}
                    src={eye_icon}
                    alt="icon"
                  />
                  <img
                    alt="icon"
                    style={{ cursor: "pointer" }}
                    src={return_icon}
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  color: "#415FAC",
                  fontSize: "16px",
                  cursor: "pointer",
                }}
              >
                123456
              </td>
              <td>Call Issue</td>
              <td>Call Issue</td>
              <td>John Doe</td>
              <td>2023-07-10 14:05</td>
              <td>
                <span
                  style={{
                    backgroundColor: "#129D54",
                    color: "white",
                    height: "25px",
                    width: "95px",
                    display: "inline-block",
                    textAlign: "center",
                    borderRadius: "100px",
                    fontSize: "14px",
                  }}
                >
                  Open
                </span>
              </td>
              <td>2023-07-10 11:00</td>
              <td>
                <div className="d-flex align-items-center gap-2 justify-content-flex-start h-100">
                  <img
                    style={{ cursor: "pointer" }}
                    src={eye_icon}
                    alt="icon"
                  />
                  <img
                    alt="icon"
                    style={{ cursor: "pointer" }}
                    src={return_icon}
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  color: "#415FAC",
                  fontSize: "16px",
                  cursor: "pointer",
                }}
              >
                123456
              </td>
              <td>Billing Issue</td>
              <td>Billing Issue</td>
              <td>John Doe</td>
              <td>2023-07-10 14:05</td>
              <td>
                <span
                  style={{
                    backgroundColor: "#FF3434",
                    color: "white",
                    height: "25px",
                    width: "95px",
                    display: "inline-block",
                    textAlign: "center",
                    borderRadius: "100px",
                    fontSize: "14px",
                  }}
                >
                  Closed
                </span>
              </td>
              <td>2023-07-10 11:00</td>
              <td>
                <div className="d-flex align-items-center gap-2 justify-content-flex-start h-100">
                  <img
                    style={{ cursor: "pointer" }}
                    src={eye_icon}
                    alt="icon"
                  />
                  <img
                    alt="icon"
                    style={{ cursor: "pointer" }}
                    src={return_icon}
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  color: "#415FAC",
                  fontSize: "16px",
                  cursor: "pointer",
                }}
              >
                123456
              </td>
              <td>Technical Support</td>
              <td>Technical Support</td>
              <td>John Doe</td>
              <td>2023-07-10 14:05</td>
              <td>
                <span
                  style={{
                    backgroundColor: "#F4A223",
                    color: "white",
                    height: "25px",
                    width: "95px",
                    display: "inline-block",
                    textAlign: "center",
                    borderRadius: "100px",
                    fontSize: "14px",
                  }}
                >
                  In Progress
                </span>
              </td>
              <td>2023-07-10 11:00</td>
              <td>
                <div className="d-flex align-items-center gap-2 justify-content-flex-start h-100">
                  <img
                    style={{ cursor: "pointer" }}
                    src={eye_icon}
                    alt="icon"
                  />
                  <img
                    alt="icon"
                    style={{ cursor: "pointer" }}
                    src={return_icon}
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  color: "#415FAC",
                  fontSize: "16px",
                  cursor: "pointer",
                }}
              >
                123456
              </td>
              <td>Call Issue</td>
              <td>Call Issue</td>
              <td>John Doe</td>
              <td>2023-07-10 14:05</td>
              <td>
                <span
                  style={{
                    backgroundColor: "#129D54",
                    color: "white",
                    height: "25px",
                    width: "95px",
                    display: "inline-block",
                    textAlign: "center",
                    borderRadius: "100px",
                    fontSize: "14px",
                  }}
                >
                  Open
                </span>
              </td>
              <td>2023-07-10 11:00</td>
              <td>
                <div className="d-flex align-items-center gap-2 justify-content-flex-start h-100">
                  <img
                    style={{ cursor: "pointer" }}
                    src={eye_icon}
                    alt="icon"
                  />
                  <img
                    alt="icon"
                    style={{ cursor: "pointer" }}
                    src={return_icon}
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  color: "#415FAC",
                  fontSize: "16px",
                  cursor: "pointer",
                }}
              >
                123456
              </td>
              <td>Billing Issue</td>
              <td>Billing Issue</td>
              <td>John Doe</td>
              <td>2023-07-10 14:05</td>
              <td>
                <span
                  style={{
                    backgroundColor: "#FF3434",
                    color: "white",
                    height: "25px",
                    width: "95px",
                    display: "inline-block",
                    textAlign: "center",
                    borderRadius: "100px",
                    fontSize: "14px",
                  }}
                >
                  Closed
                </span>
              </td>
              <td>2023-07-10 11:00</td>
              <td>
                <div className="d-flex align-items-center gap-2 justify-content-flex-start h-100">
                  <img
                    style={{ cursor: "pointer" }}
                    src={eye_icon}
                    alt="icon"
                  />
                  <img
                    alt="icon"
                    style={{ cursor: "pointer" }}
                    src={return_icon}
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </Table>
        <PaginationComponent
          currentPage={1}
          totalPages={5}
          // handleNextPage={handleNextPage}
          // handlePrevPage={handlePrevPage}
          hasNextPage={true}
          hasPreviousPage={false}
        />
        <div className="mt-5 mb-3">
          <DashBoardFooter title={"Powered by SmartServe"} />
        </div>
      </Container>
    </>
  );
};

export default Tickets;
