// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ticket-details {
  border: 1px solid #bbbbbb;
  border-radius: 15px;
  margin: 25px 0;
  padding: 15px;
}
.hr {
  border-top: 2px solid #9c9c9c;
  margin: 25px 0;
}
.chat-box {
  background-color: #f8f7fa;
  border-radius: 15px;
  padding: 25px 10px;
}
.chat-input {
  display: flex;
  align-items: center;
  gap: 5px;
}
.chat-input input,
.chat-input button {
  width: 90%;
  height: 38px;
  border-radius: 5px;
  border: 1px solid #636363;
  padding: 0 10px;
  font-size: 12px;
  font-weight: bold;
  color: #636363;
}
.chat-input button {
  width: 97px;
  background-color: #415fac;
  color: white;
  border-radius: 50px;
  border: 0;
  outline: 0;
}
`, "",{"version":3,"sources":["webpack://./src/Pages/TicketDetails/ticketDetails.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,mBAAmB;EACnB,cAAc;EACd,aAAa;AACf;AACA;EACE,6BAA6B;EAC7B,cAAc;AAChB;AACA;EACE,yBAAyB;EACzB,mBAAmB;EACnB,kBAAkB;AACpB;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,QAAQ;AACV;AACA;;EAEE,UAAU;EACV,YAAY;EACZ,kBAAkB;EAClB,yBAAyB;EACzB,eAAe;EACf,eAAe;EACf,iBAAiB;EACjB,cAAc;AAChB;AACA;EACE,WAAW;EACX,yBAAyB;EACzB,YAAY;EACZ,mBAAmB;EACnB,SAAS;EACT,UAAU;AACZ","sourcesContent":[".ticket-details {\n  border: 1px solid #bbbbbb;\n  border-radius: 15px;\n  margin: 25px 0;\n  padding: 15px;\n}\n.hr {\n  border-top: 2px solid #9c9c9c;\n  margin: 25px 0;\n}\n.chat-box {\n  background-color: #f8f7fa;\n  border-radius: 15px;\n  padding: 25px 10px;\n}\n.chat-input {\n  display: flex;\n  align-items: center;\n  gap: 5px;\n}\n.chat-input input,\n.chat-input button {\n  width: 90%;\n  height: 38px;\n  border-radius: 5px;\n  border: 1px solid #636363;\n  padding: 0 10px;\n  font-size: 12px;\n  font-weight: bold;\n  color: #636363;\n}\n.chat-input button {\n  width: 97px;\n  background-color: #415fac;\n  color: white;\n  border-radius: 50px;\n  border: 0;\n  outline: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
