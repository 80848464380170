import React, { useState } from "react";
import RestDashNav from "../RestDashNav/RestDashNav";
import { ToastContainer } from "react-toastify";
import { Col, Row } from "react-bootstrap";
import DashBoardFooter from "../DashBoardFooter/DashBoardFooter";
import { adminAddRestaurant } from "../../utilies/api";
import { notifyError, notifySuccess } from "../toasters";

const AddRestaurantList = () => {
  // const [userLoading, setUserLodaing] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const [userName, setUserName] = useState("");
  const [userPhone, setUserPhone] = useState("");
  const handleChange = (event) => {
    const newValue = event.target.value;

    // Remove any non-numeric characters
    const numericValue = newValue.replace(/\D/g, "");

    setUserPhone(numericValue);
  };
  const [userAddress, setUserAddress] = useState("");
  const [userStatus, setUserStatus] = useState(true);
  const addRestaurantFun = async () => {
    try {
      const formData = {
        email: userEmail,
        password: userPassword,
        name: userName,
        phone: userPhone,
        address: userAddress,
        status: userStatus,
      };
      const response = await adminAddRestaurant(formData);
      notifySuccess(response.data.message);
      setUserEmail("");
      setUserPassword("");
      setUserName("");
      setUserPhone("");
      setUserAddress("");
      setUserStatus(false);
    } catch (error) {
      notifyError(error.response.data.message);
    }
  };
  return (
    <>
      <RestDashNav title={"Add New Restaurant"} />
      <div className="my-orders">
        <ToastContainer />
        <div className="table-container">
          <Row className="mb-0">
            <div className="d-flex align-items-center justify-content-between my-2">
              <h1>Are you want Restaurant Active?</h1>
              <div className="rest-status">
                <div className="form-switch">
                  <input
                    type="checkbox"
                    id="custom-switch"
                    className="form-check-input"
                    checked={userStatus}
                    value={userStatus}
                    onChange={() => setUserStatus(!userStatus)}
                  />
                </div>
              </div>
            </div>
            <Col xs={12} md={6} lg={6} className="mb-4">
              <label
                style={{
                  marginBottom: "15px",
                  fontSize: "18px",
                  fontWeight: "bold",
                }}
                htmlFor="name"
              >
                Restaurant Name
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Restaurant name"
                id="name"
                name="name"
                value={userName}
                onChange={(e) => setUserName(e.target.value)}
              />
            </Col>
            <Col xs={12} md={6} lg={6} className="mb-4">
              <label
                style={{
                  marginBottom: "15px",
                  fontSize: "18px",
                  fontWeight: "bold",
                }}
                htmlFor="email"
              >
                Restaurant phone number
              </label>
              <input
                type="tel"
                className="form-control"
                placeholder="Enter phone number"
                id="phone"
                name="phone"
                value={userPhone}
                onChange={handleChange}
              />
            </Col>
            <Col xs={12} md={12} lg={12} className="mb-4">
              <label
                style={{
                  marginBottom: "15px",
                  fontSize: "18px",
                  fontWeight: "bold",
                }}
                htmlFor="address"
              >
                Address In details
              </label>
              <input
                type="text"
                className="form-control resize-none"
                id="address"
                placeholder="Address In details"
                name="address"
                value={userAddress}
                onChange={(e) => setUserAddress(e.target.value)}
              />
            </Col>
            <Col xs={12} md={12} lg={12}>
              <label
                style={{
                  marginBottom: "35px",
                  fontSize: "18px",
                  fontWeight: "bold",
                }}
                htmlFor="Account Creation"
              >
                Account Creation
              </label>
            </Col>
            <Col xs={12} md={12} lg={6} className="mb-4">
              <label
                style={{
                  marginBottom: "15px",
                  fontSize: "18px",
                  fontWeight: "bold",
                }}
                htmlFor="email"
              >
                Email Address
              </label>
              <input
                type="email"
                className="form-control"
                id="email"
                placeholder="Email Address "
                name="email"
                value={userEmail}
                onChange={(e) => setUserEmail(e.target.value)}
              />
            </Col>
            <Col xs={12} md={12} lg={6} className="mb-4">
              <label
                style={{
                  marginBottom: "15px",
                  fontSize: "18px",
                  fontWeight: "bold",
                }}
                htmlFor="password"
              >
                Password
              </label>
              <input
                type="password"
                className="form-control"
                id="password"
                placeholder="Password"
                name="password"
                value={userPassword}
                onChange={(e) => setUserPassword(e.target.value)}
              />
            </Col>
          </Row>
          <div className="d-flex justify-content-end">
            <button onClick={addRestaurantFun} className="add-item-btn">
              Save
            </button>
          </div>
        </div>
        <DashBoardFooter title={"Powered by SmartServe"} />
      </div>
    </>
  );
};

export default AddRestaurantList;
