import { Col, Row } from "react-bootstrap";
import "./dashBoardFooter.css";

const DashBoardFooter = ({ title }) => {
  return (
    <Row className="w-100">
      <Col lg={12}>
        <div className="dashboard-footer">{title}</div>
      </Col>
    </Row>
  );
};

export default DashBoardFooter;
