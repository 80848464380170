import axios from "axios";

export const base_url = "https://wizard.smartserve.ai";

// admin signin api
export const userLogin = (formData) => {
  let data = axios.post(`${base_url}/agent/auth/login`, formData, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  return data;
};

// admin reset password api
export const userResetPassword = (formData) => {
  let data = axios.post(`${base_url}/agent/auth/reset`, formData, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  return data;
};
// admin reset password api
export const adminUpdatePassword = (formData) => {
  let data = axios.put(`${base_url}/agent/auth/password`, formData, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  return data;
};

// restaurant request to join
export const getJoinRequest = async (restName, numOfBranches, createdAt) => {
  const { data } = await axios.get(`${base_url}/restaurant/request`, {
    params: {
      restaurantName: restName,
      numberOfBranches: numOfBranches,
      createdAt,
    },
    headers: {
      "Content-Type": "application/json",
    },
  });
  return data;
};

// all restaurants list
export const getAllRestaurants = async (
  restId,
  restName,
  restStatus,
  creation_date,
  page
) => {
  const { data } = await axios.get(`${base_url}/restaurant/active`, {
    params: {
      id: restId || undefined,
      name: restName || undefined,
      status: restStatus || undefined,
      created_at: creation_date || undefined,
      page: page || undefined,
    },
    headers: {
      "Content-Type": "application/json",
    },
  });
  return data;
};
// all companies
export const getAllCompanies = async (id, name, numOfMinutes) => {
  const { data } = await axios.get(`${base_url}/stt/auth`, {
    params: {
      companyId: id,
      name: name,
      numberOfMinutes: numOfMinutes,
    },
    headers: {
      "Content-Type": "application/json",
    },
  });
  return data;
};
// get total sales
export const getTotalSales = async (token) => {
  const { data } = await axios.get(`${base_url}/stt/billing`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return data;
};
// get restaurant total orders
export const getRestaurantOrders = async (token) => {
  const { data } = await axios.get(`${base_url}/agent/restaurantOrder`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return data;
};

// add restaurant api
export const addRestaurant = (formData) => {
  let data = axios.post(`${base_url}/restaurant/active`, formData, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  return data;
};

// admin add restaurant page
export const adminAddRestaurant = (formData) => {
  let data = axios.post(
    `${base_url}/restaurant/active/without-request`,
    formData,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return data;
};

// admin update Restaurant page
export const adminUpdateRestaurant = (formData) => {
  let data = axios.put(`${base_url}/restaurant/active`, formData, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  return data;
};

// admin add restaurant page amount
export const adminAddRestaurantAmount = (formData, id) => {
  let data = axios.post(
    `${base_url}/restaurant/active/${id}/subscription`,
    formData,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return data;
};

// get restaurant total orders
export const getAdminData = async (adminId) => {
  const { data } = await axios.get(`${base_url}/agent`, {
    params: {
      id: adminId,
    },
    headers: {
      "Content-Type": "application/json",
    },
  });
  return data;
};

// admin update profile api
export const adminUpdate = (formData) => {
  let data = axios.put(`${base_url}/agent`, formData, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  return data;
};
