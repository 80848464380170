// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.add-item-btn{
    border-radius: 100px;
    width: 250px;
    height: 50px;
    border: none;
    outline: none;
    background-color: #415FAC;
    color: #fff;
    font-weight: bold;
    font-size: 18px;
    letter-spacing: 1px;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Profile/profile.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;IACpB,YAAY;IACZ,YAAY;IACZ,YAAY;IACZ,aAAa;IACb,yBAAyB;IACzB,WAAW;IACX,iBAAiB;IACjB,eAAe;IACf,mBAAmB;AACvB","sourcesContent":[".add-item-btn{\n    border-radius: 100px;\n    width: 250px;\n    height: 50px;\n    border: none;\n    outline: none;\n    background-color: #415FAC;\n    color: #fff;\n    font-weight: bold;\n    font-size: 18px;\n    letter-spacing: 1px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
